import {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";

export interface BaseResponse<T> {
    status: number;
    message: string;
    data: T;
}

export interface BaseError {
    status: number;
    message: string;
    data: { [key: string]: string };
}

export interface BasePageDto<T> {
    contents: T[];
    first: boolean;
    last: boolean;
    page: number;
    totalPages: number;
    totalElements: number;
}

export const getBaseResponse = (response: AxiosResponse) => {
    return response.data;
};

export const getBaseErrorResponse = async (error: AxiosError) => {
    const baseError = error.response?.data as BaseError | undefined;

    if (baseError) {
        const { code } = baseError.data;

        if (Object.keys(ExceptionErrorCode).includes(code)) {
            return Promise.reject(error);
        }

        toast.error(baseError.message);
        return Promise.reject(error);
    }

    toast.error("error!");
    return Promise.reject(error);
};

const ExceptionErrorCode: string[] = [
    "UNKNOWN"
]
