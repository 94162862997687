import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Button, Dialog, DialogContent, Divider, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { feedApi } from "../../api/feed-api";
import { userApi } from "../../api/user-api";
import { FeedResponse } from "../../types/feed/response/FeedResponse";
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';


interface FeedDetailDialogProps {
    open: boolean;
    feedId: string | null;
    onClose: () => void;
    onActionComplete: () => void;
    highlightCommentId?: string | null;
}

const FeedDetailDialog: React.FC<FeedDetailDialogProps> = ({ open, feedId, onClose, onActionComplete, highlightCommentId }) => {
    const [feedDetail, setFeedDetail] = useState<FeedResponse | null>(null);
    const [openImageIndex, setOpenImageIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setOpenImageIndex(index);
    };

    const handleClose = () => {
        setOpenImageIndex(null);
    };

    const fetchFeedDetail = async () => {
        if (!feedId) return;
        try {
            const response = await feedApi.getFeedDetail(feedId);
            setFeedDetail(response.data);
        } catch (error) {
        }
    };

    const handleDeleteFeed = async () => {
        if (!feedId) return;
        const confirm = window.confirm("이 피드를 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.");
        if (!confirm) return;
        try {
            await feedApi.deleteFeed(feedId);
            toast.success("피드가 삭제되었습니다.");
            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    const handleDisableFeedWriter = async () => {
        if (!feedDetail) return;
        const confirm = window.confirm("작성자를 비활성화하면 모든 게시글과 댓글이 비활성화됩니다. 계속하시겠습니까?");
        if (!confirm) return;
        try {
            await userApi.blockUser(feedDetail.writeUser.userId);
            toast.success("작성자가 비활성화되었습니다.");
            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    const handleDeleteComment = async (commentId: string) => {
        const confirm = window.confirm("이 댓글을 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.");
        if (!confirm) return;
        try {
            await feedApi.deleteComment(commentId);
            toast.success("댓글이 삭제되었습니다.");
            onActionComplete();
            fetchFeedDetail();
        } catch (error) {
        }
    };

    const handleDisableCommentWriter = async (userId: string) => {
        const confirm = window.confirm("작성자를 비활성화하면 모든 게시글과 댓글이 비활성화됩니다. 계속하시겠습니까?");
        if (!confirm) return;
        try {
            await userApi.blockUser(userId);
            toast.success("댓글 작성자가 비활성화되었습니다.");
            onActionComplete();
            fetchFeedDetail();
        } catch (error) {
        }
    };

    useEffect(() => {
        if (open) fetchFeedDetail();
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContentWrapper>
                {feedDetail ? (
                    <Box>
                        {/* 작성자 정보 */}
                        <AuthorInfo>
                            <ProfileImage src={feedDetail?.writeUser?.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`} />
                            <Box ml={2}>
                                <Typography variant="h6">
                                    {feedDetail.writeUser.nickname}
                                    {feedDetail.writeUser.goal && (
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                ml: 0.5
                                            }}
                                        >
                                            {feedDetail.writeUser.goal}
                                        </Typography>
                                    )}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">{feedDetail.createdAt}</Typography>
                            </Box>
                        </AuthorInfo>

                        {/* 피드 내용 */}
                        <FeedContent>
                            {feedDetail.dayOfExercise && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#075EE1',
                                        fontWeight: 'bold',
                                        mb: 1
                                    }}
                                >
                                    #부라보 운동 {feedDetail.dayOfExercise}일차
                                </Typography>
                            )}
                            <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>{feedDetail.content}</Typography>
                            <ImageGrid>
                                {feedDetail.images.map((image, index) => (
                                    <ImagePreview
                                        key={index}
                                        src={image}
                                        alt={`피드 이미지 ${index + 1}`}
                                        onClick={() => handleImageClick(index)}
                                    />
                                ))}
                            </ImageGrid>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                                pt: 1
                            }}>
                                <Box>
                                    <Box sx={{ display: 'flex', gap: 1.5, mb: 1 }}>
                                        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>
                                            좋아요 {feedDetail.likeCount}개
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>
                                            댓글 {feedDetail.commentCount}개
                                        </Typography>
                                    </Box>
                                    {feedDetail.feedLikePreviewUsers.length > 0 && (
                                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                                            {feedDetail.feedLikePreviewUsers.map((user, index) => (
                                                <Tooltip key={index} title={user.nickname}>
                                                    <SmallProfileImage
                                                        size={36}
                                                        src={user.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleDeleteFeed}
                                        startIcon={<CancelIcon />}
                                        size="small"
                                    >
                                        피드 삭제하기
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        onClick={handleDisableFeedWriter}
                                        startIcon={<BlockIcon />}
                                        size="small"
                                    >
                                        작성자 비활성화
                                    </Button>
                                </Box>
                            </Box>
                        </FeedContent>

                        <Divider sx={{ my: 2 }} />

                        {/* 댓글 섹션 */}
                        <Typography variant="h6" gutterBottom>
                            댓글
                        </Typography>
                        {feedDetail.comments.length > 0 ? (
                            feedDetail.comments.map((comment) => (
                                <CommentCard key={comment.commentId} isHighlighted={comment.commentId === highlightCommentId}>
                                    <CommentHeader>
                                        <Box display="flex" alignItems="center">
                                            <ProfileImage src={comment.writeUser?.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`} />
                                            <Box ml={2}>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#2c3e50'
                                                    }}
                                                >
                                                    {comment.writeUser.nickname}
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            ml: 1,
                                                            color: '#7f8c8d',
                                                            fontWeight: 400,
                                                            fontSize: '0.8rem'
                                                        }}
                                                    >
                                                        {comment.writeUser.goal}
                                                    </Typography>
                                                </Typography>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            color: '#7f8c8d',
                                                            fontSize: '0.75rem'
                                                        }}
                                                    >
                                                        {comment.createdAt}
                                                    </Typography>
                                                    <Box display="flex" alignItems="center" gap={0.5}>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                color: '#7f8c8d',
                                                                fontSize: '0.75rem'
                                                            }}
                                                        >
                                                            • 좋아요 {comment.likeCount}개
                                                        </Typography>
                                                        {comment.commentLikePreviewUsers?.length > 0 && (
                                                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                                                {comment.commentLikePreviewUsers.map((user, index) => (
                                                                    <Tooltip key={index} title={user.nickname}>
                                                                        <SmallProfileImage
                                                                            size={24}
                                                                            src={user.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                                                        />
                                                                    </Tooltip>
                                                                ))}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CommentHeader>
                                    <CommentContent
                                        sx={{
                                            whiteSpace: "pre-wrap",
                                            py: 1.5,
                                            px: 1,
                                            backgroundColor: '#f8f9fa',
                                            borderRadius: 1,
                                            my: 1
                                        }}
                                    >
                                        {comment.mentionUser && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    color: '#075EE1',
                                                    mr: 1
                                                }}
                                            >
                                                @{comment.mentionUser.nickname}
                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        color: comment.mentionUser.status === "ACTIVE"
                                                            ? "#4caf50"  // 활성 상태일 때 초록색
                                                            : comment.mentionUser.status === "BLOCKED"
                                                                ? "#f57c00" // 비활성 상태일 때 주황색 
                                                                : "#d32f2f", // 탈퇴 상태일 때 빨간색
                                                        ml: 0.5
                                                    }}
                                                >
                                                    ({comment.mentionUser.status === "ACTIVE"
                                                        ? "활성유저"
                                                        : comment.mentionUser.status === "BLOCKED"
                                                            ? "비활성유저"
                                                            : "탈퇴유저"})
                                                </Typography>
                                            </Typography>
                                        )}
                                        {comment.content}
                                    </CommentContent>
                                    <ActionButtons>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            onClick={() => handleDeleteComment(comment.commentId)}
                                            startIcon={<CancelIcon />}
                                        >
                                            댓글 삭제
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            size="small"
                                            onClick={() => handleDisableCommentWriter(comment.writeUser.userId)}
                                            startIcon={<BlockIcon />}
                                        >
                                            작성자 비활성화
                                        </Button>
                                    </ActionButtons>
                                </CommentCard>
                            ))
                        ) : (
                            <Typography color="textSecondary">댓글이 없습니다.</Typography>
                        )}
                    </Box>
                ) : (
                    <Typography>피드 정보를 불러오는 중입니다...</Typography>
                )}
            </DialogContentWrapper>

            {openImageIndex !== null && (
                <Dialog open={openImageIndex !== null} onClose={handleClose} fullWidth>
                    <DialogContent>
                        <CloseButton onClick={handleClose}>
                            닫기
                        </CloseButton>
                        <ImageFullscreen src={feedDetail?.images[openImageIndex]} alt={`확대된 이미지 ${openImageIndex + 1}`} />
                    </DialogContent>
                </Dialog>
            )}
        </Dialog>
    );
};

export default FeedDetailDialog;

// 스타일 정의
const DialogContentWrapper = styled(DialogContent)`
  background-color: #f9f9f9;
`;

const AuthorInfo = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ebedf3;
`;

const SmallProfileImage = styled.img<{ size?: number }>`
    width: ${props => props.size || 36}px;
    height: ${props => props.size || 36}px;
    border-radius: 50%;
    border: 1px solid #ebedf3;
`;

const FeedContent = styled(Box)`
  margin-bottom: 20px;
`;

const ImageGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ebedf3;
`;

const ActionButtons = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const CommentCard = styled(Paper) <{ isHighlighted?: boolean }>`
  padding: 16px;
  margin-bottom: 15px;
  border-radius: 12px;
  background-color: ${({ isHighlighted }) => (isHighlighted ? "#fff3f0" : "#fff")};
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid ${({ isHighlighted }) => (isHighlighted ? "#ffccc7" : "#e8e8e8")};
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-1px);
  }
`;

const CommentHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const CommentContent = styled(Typography)`
  margin: 10px 0;
  font-size: 0.95rem;
  color: #333;
`;

const ImageFullscreen = styled.img`
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* 정사각형 모양에 약간의 둥근 모서리 */
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px); /* 살짝 위로 올라가는 효과 */
  }

  &:active {
    transform: translateY(0); /* 클릭 시 원래 위치로 */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); /* 클릭 시 그림자 감소 */
  }
`;
