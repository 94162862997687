import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Chip,
    Dialog,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { notificationBatchApi } from "../../api/notification-batch-api";
import { NotificationBatchResponse } from "../../types/notification/response/NotificationBatchResponse";
import { getNotificationBatchStatusLabel, NotificationBatchStatus } from "../../types/notification/entity/NotificationBatchStatus";
import NotificationBatchDialog from "./NotificationBatchDialog";
import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import { getCustomDayOfWeekLabel, getCustomDayOfWeekLabels } from "../../types/notification/entity/CustomDayOfWeek";
import { getNotificationBatchConditionLabel, getNotificationBatchConditionLabels } from "../../types/notification/entity/NotificationBatchCondition";
import { getNotificationLinkTypeLabel, NotificationLinkType } from "../../types/notification/entity/NotificationLinkType";
import { noticeApi } from "../../api/notice-api";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import CheckIcon from "@mui/icons-material/Check";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledTable = styled(Table)`
  min-width: 100%;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

const NoticeImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const NotificationBatchPage: React.FC = () => {
    const [batches, setBatches] = useState<NotificationBatchResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState<NotificationBatchResponse | null>(null);
    const [noticeDialogOpen, setNoticeDialogOpen] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState<NoticeResponse | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const fetchBatches = async () => {
        setLoading(true);
        try {
            const response = await notificationBatchApi.getNotificationBatches();
            setBatches(response.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (batch: NotificationBatchResponse | null) => {
        setSelectedBatch(batch);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedBatch(null);
        setDialogOpen(false);
    };

    const handleDeleteBatch = async (batchId: string) => {
        if (window.confirm("해당 배치를 삭제하시겠습니까?")) {
            try {
                await notificationBatchApi.deleteNotificationBatch(batchId);
                toast.success("배치가 삭제되었습니다.");
                fetchBatches();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeStatus = async (batchId: string, targetStatus: NotificationBatchStatus) => {
        const message = targetStatus === NotificationBatchStatus.ACTIVE
            ? "알림을 활성화하시겠습니까?"
            : "알림을 중단하시겠습니까?";

        if (window.confirm(message)) {
            try {
                if (targetStatus === NotificationBatchStatus.ACTIVE) {
                    await notificationBatchApi.activateNotificationBatch(batchId);
                } else if (targetStatus === NotificationBatchStatus.PAUSE) {
                    await notificationBatchApi.pauseNotificationBatch(batchId);
                }
                toast.success("상태가 변경되었습니다.");
                fetchBatches();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleCompleteSaveOrUpdate = () => {
        fetchBatches();
        setDialogOpen(false);
        setSelectedBatch(null);
    };

    const formatTime = (time: string | undefined): string => {
        if (!time) return "-";
        const match = time.match(/^(\d{2}:\d{2})/);
        return match ? match[1] : time;
    };

    const fetchNotice = async (noticeId: string) => {
        try {
            const response = await noticeApi.getNotice(noticeId);
            setSelectedNotice(response.data);
        } catch (error) { }
    };

    const onImageClick = (image: string) => {
        setSelectedImage(image);
    };

    useEffect(() => {
        fetchBatches();
    }, []);

    return (
        <Box p={4}>
            <PageHeader title="정기 알림 관리" action={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialog(null)}
                    startIcon={<AddIcon />}
                >
                    추가
                </Button>
            } />

            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>제목</StyledTableCell>
                            <StyledTableCell>내용</StyledTableCell>
                            <StyledTableCell>요일</StyledTableCell>
                            <StyledTableCell>시간</StyledTableCell>
                            <StyledTableCell>조건</StyledTableCell>
                            <StyledTableCell>연결 유형</StyledTableCell>
                            <StyledTableCell>연결 컨텐츠</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>액션</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    데이터 로딩 중...
                                </TableCell>
                            </TableRow>
                        ) : (
                            batches.map((batch) => (
                                <StyledTableRow
                                    key={batch.id}
                                    onClick={() => handleOpenDialog(batch)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                                >
                                    <StyledTableCell>{batch.title || "-"}</StyledTableCell>
                                    <StyledTableCell>{batch.content || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                            {(batch.daysOfWeek || []).map((day) => (
                                                <Chip
                                                    key={day}
                                                    label={getCustomDayOfWeekLabel(day)}
                                                    size="small"
                                                    variant="filled"
                                                    sx={{
                                                        bgcolor: '#E3F2FD',
                                                        color: '#1565C0',
                                                        fontWeight: 500
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell>{formatTime(batch.time) || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                            {(batch.conditions || []).map((condition) => (
                                                <Chip
                                                    key={condition}
                                                    label={getNotificationBatchConditionLabel(condition)}
                                                    size="small"
                                                    variant="filled"
                                                    sx={{
                                                        bgcolor: '#F3E5F5',
                                                        color: '#6A1B9A',
                                                        fontWeight: 500
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell>{getNotificationLinkTypeLabel(batch.linkType || "")}</StyledTableCell>
                                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                                        {batch.linkType === NotificationLinkType.NOTICE ? (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => batch.linkId && fetchNotice(batch.linkId)}
                                                startIcon={<CheckIcon sx={{ fontSize: 16 }} />}
                                            >
                                                확인하기
                                            </Button>
                                        ) : (
                                            batch.linkId || "-"
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={getNotificationBatchStatusLabel(batch.status || "")}
                                            color={
                                                batch.status === NotificationBatchStatus.ACTIVE
                                                    ? "success"
                                                    : batch.status === NotificationBatchStatus.PAUSE
                                                        ? "warning"
                                                        : "error"
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                                        {batch.status === NotificationBatchStatus.ACTIVE && (
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                onClick={() => handleChangeStatus(batch.id, NotificationBatchStatus.PAUSE)}
                                                size="small"
                                                sx={{ marginRight: "8px" }}
                                                startIcon={<PauseIcon sx={{ fontSize: 16 }} />}
                                            >
                                                중단
                                            </Button>
                                        )}
                                        {batch.status === NotificationBatchStatus.PAUSE && (
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => handleChangeStatus(batch.id, NotificationBatchStatus.ACTIVE)}
                                                size="small"
                                                sx={{ marginRight: "8px" }}
                                                startIcon={<PlayArrowIcon sx={{ fontSize: 16 }} />}
                                            >
                                                활성화
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleteBatch(batch.id)}
                                            size="small"
                                            startIcon={<DeleteIcon sx={{ fontSize: 16 }} />}
                                        >
                                            삭제
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            <NotificationBatchDialog
                open={dialogOpen}
                batch={selectedBatch}
                onClose={handleCloseDialog}
                onComplete={handleCompleteSaveOrUpdate}
            />

            {selectedNotice && (
                <Dialog
                    open={true}
                    onClose={() => setSelectedNotice(null)}
                    fullWidth
                    maxWidth="sm"
                    PaperProps={{
                        sx: {
                            borderRadius: '12px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                            padding: '24px'
                        }
                    }}
                >
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>
                            {selectedNotice.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#757575', mt: 1 }}>
                            {selectedNotice.createdAt}
                        </Typography>
                        <Typography variant="body1" sx={{
                            mt: 2,
                            color: '#424242',
                            whiteSpace: "pre-wrap",
                            lineHeight: 1.6
                        }}>
                            {selectedNotice.content}
                        </Typography>
                        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                            {selectedNotice.images.map((image, index) => (
                                <NoticeImage
                                    key={index}
                                    src={image}
                                    alt={`공지사항 이미지 ${index + 1}`}
                                    onClick={() => onImageClick(image)}
                                />
                            ))}
                        </Box>
                        <Box sx={{ position: 'absolute', top: 24, right: 24 }}>
                            <IconButton
                                onClick={() => setSelectedNotice(null)}
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                    }
                                }}
                            >
                                ✕
                            </IconButton>
                        </Box>
                    </Box>
                </Dialog>
            )}
        </Box>
    );
};

export default NotificationBatchPage;