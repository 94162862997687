import { AxiosInstance } from "axios";
import { BaseResponse } from "./base-response";
import { baseApi } from "./base-api";

class ImageApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async uploadImages(files: FileList | File[]): Promise<BaseResponse<string[]>> {
        const formData = new FormData();
        Array.from(files).forEach((file) => {
            formData.append("files", file);
        });

        return await this._api.post("/api/images", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}

export const imageApi = new ImageApi(baseApi);
