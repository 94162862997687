import {UserResponse} from "../../user/response/UserResponse";

export interface ReportResponse {
    id: string; // 신고 ID
    reason: string; // 신고 사유
    targetContentType: string; // 신고 대상의 컨텐츠 타입
    targetContentId: string; // 신고 대상의 컨텐츠 ID
    targetUser: UserResponse; // 신고 대상 사용자 정보
    reportUser: UserResponse; // 신고한 사용자 정보
    status: string; // 신고 상태 ('REPORTED' or 'CONFIRMED')
    isBlock: boolean; // 차단 여부
    createdAt: string; // 신고 생성 일자 (yyyy-MM-dd HH:mm:ss)
}

export interface ReportSearchParams {
    status?: ReportStatus | null; // undefined 허용
    page: number;
    size: number;
}

export enum ReportStatus {
    REPORTED = "REPORTED", // 신고 초기 상태
    CONFIRMED = "CONFIRMED", // 신고 처리 완료 상태
}
