import React from 'react';
import { Dialog, Box, Typography, IconButton } from '@mui/material';
import { NoticeResponse } from '../../types/notice/response/NoticeResponse';
import styled from 'styled-components';

interface NoticeDetailDialogProps {
    open: boolean;
    notice: NoticeResponse;
    onClose: () => void;
    onImageClick: (image: string) => void;
}

const NoticeDetailDialog: React.FC<NoticeDetailDialogProps> = ({
    open,
    notice,
    onClose,
    onImageClick
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                    padding: '24px'
                }
            }}
        >
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>
                    {notice.title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#757575', mt: 1 }}>
                    {notice.createdAt}
                </Typography>
                <Typography variant="body1" sx={{
                    mt: 2,
                    color: '#424242',
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.6
                }}>
                    {notice.content}
                </Typography>
                <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                    {notice.images.map((image, index) => (
                        <NoticeImage
                            key={index}
                            src={image}
                            alt={`공지사항 이미지 ${index + 1}`}
                            onClick={() => onImageClick(image)}
                        />
                    ))}
                </Box>
                <Box sx={{ position: 'absolute', top: 24, right: 24 }}>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)'
                            }
                        }}
                    >
                        ✕
                    </IconButton>
                </Box>
            </Box>
        </Dialog>
    );
};

const NoticeImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

export default NoticeDetailDialog;
