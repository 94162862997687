import {HelmetProvider} from "react-helmet-async";
import './App.css';
import GlobalStyle from "../src/globalStyle";
import * as React from "react";
import {profileState} from "./app/reducers/profile-slice";
import {useAppSelector} from "./app/reducers/hooks";
import Home from "./views/Home";
import SignIn from "./views/SignIn";

const App = () => {
    const { isLoggedIn } = useAppSelector(profileState);

  return (
      <>
        <GlobalStyle />
        <HelmetProvider>
            {isLoggedIn ? <Home /> : <SignIn />}
        </HelmetProvider>
      </>
  );
};

export default App;
