import { AxiosInstance } from "axios";
import { BasePageDto } from "./base-response";
import { baseApi } from "./base-api";
import {ReportResponse, ReportSearchParams} from "../types/report/response/ReportResponse";

class ReportApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getPagedReports(params: ReportSearchParams): Promise<BasePageDto<ReportResponse>> {
        const response = await this._api.get<BasePageDto<ReportResponse>>("/api/admin/reports", { params });
        return response.data;
    }

    async confirmReport(reportId: string): Promise<void> {
        await this._api.post(`/api/admin/reports/${reportId}/confirm`);
    }
}

export const reportApi = new ReportApi(baseApi);
