import React from "react";
import styled from "styled-components";
import { Dialog, DialogContent, Box } from "@mui/material";

interface ImageViewerDialogProps {
    open: boolean;
    image: string | null;
    onClose: () => void;
}

const ImageViewerDialog: React.FC<ImageViewerDialogProps> = ({ open, image, onClose }) => {
    if (!image) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogContent>
                <ImageWrapper>
                    <LargeImage src={image} alt="공지사항 이미지" />
                </ImageWrapper>
            </DialogContent>
        </Dialog>
    );
};

export default ImageViewerDialog;

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa;
`;

const LargeImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
`;
