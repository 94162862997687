import { AxiosInstance } from "axios";
import { baseApi } from "./base-api";
import {BaseResponse} from "./base-response";
import {TopBannerSetResponse} from "../types/banner/response/TopBannerSetResponse";

class BannerSetApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getActiveBannerSet(): Promise<BaseResponse<TopBannerSetResponse>> {
        return await this._api.get(`/api/top-banner-sets/active`);
    }

    async getInactiveBannerSets(): Promise<BaseResponse<TopBannerSetResponse[]>> {
        return await this._api.get(`/api/top-banner-sets/inactive`);
    }

    async createBannerSet(request: { bannerIds: string[] }): Promise<void> {
        await this._api.post("/api/top-banner-sets", request);
    }

    async updateBannerSet(id: string, request: { bannerIds: string[] }): Promise<void> {
        await this._api.put(`/api/top-banner-sets/${id}`, request);
    }

    async activateBannerSet(id: string): Promise<void> {
        await this._api.post(`/api/top-banner-sets/${id}/activate`);
    }

    async inactivateBannerSet(id: string): Promise<void> {
        await this._api.post(`/api/top-banner-sets/${id}/inactivate`);
    }

    async deleteBannerSet(id: string): Promise<void> {
        await this._api.delete(`/api/top-banner-sets/${id}`);
    }
}

export const bannerSetApi = new BannerSetApi(baseApi);
