import { AxiosInstance } from "axios";
import { baseApi } from "./base-api";
import {BasePageDto} from "./base-response";
import {DailyDataAnalysisResponse} from "../types/analytics/response/DailyDataAnalysisResponse";

class AnalyticsApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getDailyDataAnalysis(
        params: { startDate?: string; endDate?: string; page: number; size: number }
    ): Promise<BasePageDto<DailyDataAnalysisResponse>> {
        const response = await this._api.get<BasePageDto<DailyDataAnalysisResponse>>("/api/daily-data-analysis", { params });
        return response.data;
    }
}

export const analyticsApi = new AnalyticsApi(baseApi);
