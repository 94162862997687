import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const AlertBox = styled(Box) <{
  bgcolor?: string;
  borderColor?: string;
  borderRadius?: string;
  textColor?: string;
  padding?: string;
}>`
  padding: ${({ padding }) => padding || '0px'};
  background-color: ${({ bgcolor }) => bgcolor || '#ffebee'};
  border: 1px solid ${({ borderColor }) => borderColor || '#f44336'};
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
`;

export const AlertText = styled(Typography) <{
  textColor?: string;
  whiteSpace?: string;
}>`
  color: ${({ textColor }) => textColor || '#f44336'};
  font-size: 14px;
  line-height: 1.5;
  white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
`;
