import React from "react";
import { Box } from "@mui/material";

interface BannerPreviewProps {
    backgroundImageUrl?: string;
    foregroundImageUrl?: string;
}

const BannerPreview: React.FC<BannerPreviewProps> = ({ backgroundImageUrl, foregroundImageUrl }) => {
    return (
        <Box
            sx={{
                width: "360px",
                height: "80px",
                position: "relative",
                margin: "0 auto",
            }}
        >
            {/* Background Image */}
            {backgroundImageUrl && (
                <img
                    src={backgroundImageUrl}
                    alt="Background"
                    draggable="false"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        userSelect: "none",
                    }}
                />
            )}
            {/* Foreground Image */}
            {foregroundImageUrl && (
                <img
                    src={foregroundImageUrl}
                    alt="Foreground"
                    draggable="false"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "300px",
                        height: "80px",
                        objectFit: "contain",
                        userSelect: "none",
                    }}
                />
            )}
        </Box>
    );
};

export default BannerPreview;
