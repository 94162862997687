import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { bannerApi } from "../../api/banner-api";
import { bannerSetApi } from "../../api/banner-set-api";
import { Banner } from "../../types/banner/Banner";
import { TopBannerSetResponse } from "../../types/banner/response/TopBannerSetResponse";
import BannerPreview from "../../components/BannerPreview";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import SortableBannerSetItem from "../../components/SortableBannerSetItem";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

const StyledBannerSetContainer = styled.div<{ isActive: boolean }>`
    padding: 24px;
    margin-bottom: 32px;
    border: 2px solid ${({ isActive }) => (isActive ? "#4caf50" : "#f44336")};
    border-radius: 12px;
    background-color: ${({ isActive }) =>
        isActive ? "rgba(76, 175, 80, 0.04)" : "rgba(244, 67, 54, 0.04)"};
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
`;

const StyledBannerCard = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px;
    gap: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }
`;

const ActionButtons = styled(Box)`
    display: flex;
    gap: 12px;
    margin-top: 16px;
`;

const BannerSet: React.FC = () => {
    const [activeSet, setActiveSet] = useState<TopBannerSetResponse | null>(null);
    const [inactiveSets, setInactiveSets] = useState<TopBannerSetResponse[]>([]);
    const [banners, setBanners] = useState<Banner[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedBanners, setSelectedBanners] = useState<Banner[]>([]);
    const [editingSetId, setEditingSetId] = useState<string | null>(null);

    const fetchBannerSets = async () => {
        try {
            const activeResponse = await bannerSetApi.getActiveBannerSet();
            setActiveSet(activeResponse.data);

            const inactiveResponse = await bannerSetApi.getInactiveBannerSets();
            setInactiveSets(inactiveResponse.data || []);
        } catch (error) {
            setActiveSet(null);
            setInactiveSets([]);
        }
    };

    const fetchBanners = async () => {
        try {
            const response = await bannerApi.getBanners({ status: "ACTIVE" });
            setBanners(response.contents);
        } catch (error) {
            toast.error("배너를 불러오는데 실패했습니다.");
        }
    };

    const handleActivate = async (id: string) => {
        try {
            await bannerSetApi.activateBannerSet(id);
            toast.success("배너 세트가 활성화되었습니다.");
            fetchBannerSets();
        } catch (error) { }
    };

    const handleInactivate = async (id: string) => {
        const confirmInactivate = window.confirm("정말로 배너 세트를 비활성화하시겠습니까?");
        if (!confirmInactivate) return;

        try {
            await bannerSetApi.inactivateBannerSet(id);
            toast.success("배너 세트가 비활성화되었습니다.");
            fetchBannerSets();
        } catch (error) { }
    };

    const handleDelete = async (id: string) => {
        if (!window.confirm("정말로 삭제하시겠습니까?")) return;
        try {
            await bannerSetApi.deleteBannerSet(id);
            toast.success("배너 세트가 삭제되었습니다.");
            fetchBannerSets();
        } catch (error) { }
    };

    const handleSaveSet = async () => {
        if (selectedBanners.length === 0) {
            toast.error("배너를 최소 1개 이상 추가해주세요.");
            return;
        }
        try {
            if (editingSetId) {
                await bannerSetApi.updateBannerSet(editingSetId, {
                    bannerIds: selectedBanners.map((banner) => banner.id),
                });
                toast.success("배너 세트가 수정되었습니다.");
            } else {
                await bannerSetApi.createBannerSet({
                    bannerIds: selectedBanners.map((banner) => banner.id),
                });
                toast.success("배너 세트가 생성되었습니다.");
            }
            setDialogOpen(false);
            fetchBannerSets();
        } catch (error) { }
    };

    const handleDialogOpen = async (set?: TopBannerSetResponse) => {
        await fetchBanners();

        setEditingSetId(set?.id || null);

        const matchedBanners = set?.banners || [];

        setSelectedBanners(matchedBanners);
        setDialogOpen(true);
    };

    const moveBanner = (index: number, direction: "up" | "down") => {
        const newBanners = [...selectedBanners];
        const targetIndex = direction === "up" ? index - 1 : index + 1;

        if (targetIndex < 0 || targetIndex >= newBanners.length) return;

        // Swap banners
        [newBanners[index], newBanners[targetIndex]] = [newBanners[targetIndex], newBanners[index]];
        setSelectedBanners(newBanners);
    };

    useEffect(() => {
        fetchBannerSets();
    }, []);

    return (
        <Box p={4}>
            <PageHeader
                title="배너 세트 관리"
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleDialogOpen()}
                    >
                        배너 세트 추가
                    </Button>
                }
            />

            {/* 활성화된 배너 세트 */}
            <Box mb={5} sx={{ backgroundColor: '#fff', padding: '24px', borderRadius: '12px', boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                <Typography
                    variant="h5"
                    sx={{
                        mb: 3,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#2E7D32',
                        '&::before': {
                            content: '""',
                            width: '4px',
                            height: '24px',
                            backgroundColor: '#4CAF50',
                            marginRight: '12px',
                            borderRadius: '2px'
                        }
                    }}
                >
                    활성화된 배너 세트
                </Typography>
                {activeSet ? (
                    <StyledBannerSetContainer isActive={true}>
                        {activeSet.banners.map((banner) => (
                            <StyledBannerCard key={banner.id}>
                                <BannerPreview
                                    backgroundImageUrl={banner.backgroundImageUrl}
                                    foregroundImageUrl={banner.foregroundImageUrl}
                                />
                                <Typography sx={{ flex: 1, fontWeight: 500 }}>
                                    {banner.notice.title}
                                </Typography>
                            </StyledBannerCard>
                        ))}
                        <ActionButtons>
                            <Button
                                variant="outlined"
                                startIcon={<EditIcon />}
                                onClick={() => handleDialogOpen(activeSet)}
                            >
                                수정
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<BlockIcon />}
                                onClick={() => handleInactivate(activeSet.id)}
                            >
                                비활성화
                            </Button>
                        </ActionButtons>
                    </StyledBannerSetContainer>
                ) : (
                    <Typography color="textSecondary">
                        활성화된 배너 세트가 없습니다.
                    </Typography>
                )}
            </Box>

            {/* 비활성화된 배너 세트 */}
            <Box sx={{ backgroundColor: '#fff', padding: '24px', borderRadius: '12px', boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                <Typography
                    variant="h5"
                    sx={{
                        mb: 3,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#C62828',
                        '&::before': {
                            content: '""',
                            width: '4px',
                            height: '24px',
                            backgroundColor: '#EF5350',
                            marginRight: '12px',
                            borderRadius: '2px'
                        }
                    }}
                >
                    비활성화된 배너 세트
                </Typography>
                <Divider sx={{ my: 2 }} />
                {inactiveSets.length > 0 ? (
                    inactiveSets.map((set) => (
                        <StyledBannerSetContainer key={set.id} isActive={false}>
                            {set.banners.map((banner) => (
                                <StyledBannerCard key={banner.id}>
                                    <BannerPreview
                                        backgroundImageUrl={banner.backgroundImageUrl}
                                        foregroundImageUrl={banner.foregroundImageUrl}
                                    />
                                    <Typography sx={{ flex: 1 }}>{banner.notice.title}</Typography>
                                </StyledBannerCard>
                            ))}
                            <ActionButtons>
                                <Button
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    onClick={() => handleDialogOpen(set)}
                                >
                                    수정
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<CheckIcon />}
                                    onClick={() => handleActivate(set.id)}
                                >
                                    활성화
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<BlockIcon />}
                                    onClick={() => handleDelete(set.id)}
                                >
                                    삭제
                                </Button>
                            </ActionButtons>
                        </StyledBannerSetContainer>
                    ))
                ) : (
                    <Typography>비활성화된 배너 세트가 없습니다.</Typography>
                )}
            </Box>

            {/* 세트 추가/수정 Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    {editingSetId ? "배너 세트 수정" : "배너 세트 추가"}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            배너 선택
                        </Typography>
                        <Box
                            sx={{
                                mt: 2,
                                minHeight: "50px",
                                border: "1px dashed #ccc",
                                padding: "16px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "8px",
                            }}
                        >
                            <DndContext
                                sensors={useSensors(
                                    useSensor(PointerSensor),
                                    useSensor(KeyboardSensor, {
                                        coordinateGetter: sortableKeyboardCoordinates,
                                    })
                                )}
                                collisionDetection={closestCenter}
                                onDragEnd={(event: DragEndEvent) => {
                                    const { active, over } = event;

                                    if (active.id !== over?.id) {
                                        const oldIndex = selectedBanners.findIndex(
                                            (banner) => banner.id === active.id
                                        );
                                        const newIndex = selectedBanners.findIndex(
                                            (banner) => banner.id === over?.id
                                        );

                                        if (oldIndex !== -1 && newIndex !== -1) {
                                            setSelectedBanners(arrayMove(selectedBanners, oldIndex, newIndex));
                                        }
                                    }
                                }}
                            >
                                <SortableContext
                                    items={selectedBanners.map((banner) => banner.id)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {selectedBanners.map((banner, index) => (
                                        <Box
                                            key={banner.id}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "8px",
                                                width: "100%"
                                            }}
                                        >
                                            <Box sx={{ flex: "7", marginRight: "8px" }}>
                                                <SortableBannerSetItem
                                                    id={banner.id}
                                                    name={banner.notice.title}
                                                    banner={banner}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flex: "3",
                                                    gap: "8px"
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() => moveBanner(index, "up")}
                                                    disabled={index === 0}
                                                    aria-label="위로 이동"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ArrowUpwardIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => moveBanner(index, "down")}
                                                    disabled={index === selectedBanners.length - 1}
                                                    aria-label="아래로 이동"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedBanners((prev) =>
                                                            prev.filter((b) => b.id !== banner.id)
                                                        );
                                                    }}
                                                    aria-label="삭제"
                                                    color="error"
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </Box>

                        <Select
                            fullWidth
                            value=""
                            onChange={(e) => {
                                const banner = banners.find((b) => b.id === e.target.value);
                                if (banner && !selectedBanners.some((b) => b.id === banner.id)) {
                                    setSelectedBanners((prev) => [...prev, banner]);
                                }
                            }}
                            sx={{ marginTop: "24px" }}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                배너 추가
                            </MenuItem>
                            {banners
                                .filter((banner) => !selectedBanners.some((b) => b.id === banner.id))
                                .map((banner) => (
                                    <MenuItem key={banner.id} value={banner.id}>
                                        {banner.notice.title}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        variant="outlined"
                        startIcon={<CancelIcon />}
                    >
                        취소
                    </Button>
                    <Button
                        onClick={handleSaveSet}
                        variant="contained"
                        color="primary"
                        disabled={selectedBanners.length === 0}
                        startIcon={<CheckIcon />}
                    >
                        저장
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default BannerSet;
