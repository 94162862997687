import React, { useEffect, useState } from "react";
import { Banner } from "../../types/banner/Banner";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import { bannerApi } from "../../api/banner-api";
import { toast } from "react-toastify";
import { noticeApi } from "../../api/notice-api";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { imageApi } from "../../api/image-api";
import PageHeader from "../../components/PageHeader";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";

const StyledBannerBox = styled(Box)(({ theme }) => ({
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '100%', // 360px에서 100%로 변경
    maxWidth: '360px', // 최대 너비 추가
    margin: '0 auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)'
}));

const ActionButtonsBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
}));

const Banners: React.FC = () => {
    const [banners, setBanners] = useState<Banner[]>([]);
    const [notices, setNotices] = useState<NoticeResponse[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editingBannerId, setEditingBannerId] = useState<string | null>(null);

    const [foregroundImage, setForegroundImage] = useState<string | File | null>(null);
    const [backgroundImage, setBackgroundImage] = useState<string | File | null>(null);
    const [noticeId, setNoticeId] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const fetchBanners = async (page: number) => {
        try {
            const response = await bannerApi.getBanners({ status: "ACTIVE", page });
            setBanners(response.contents);
            setCurrentPage(response.page);
            setTotalPages(response.totalPages);
        } catch (error) { }
    };

    const fetchNotices = async () => {
        try {
            const response = await noticeApi.getNotices();
            setNotices(response.data);
        } catch (error) { }
    };

    const handleSaveBanner = async () => {
        if (!foregroundImage || !backgroundImage || !noticeId) return;

        try {
            let foregroundImageUrl = typeof foregroundImage === "string" ? foregroundImage : "";
            let backgroundImageUrl = typeof backgroundImage === "string" ? backgroundImage : "";

            if (foregroundImage instanceof File || backgroundImage instanceof File) {
                const uploadFiles: File[] = [];
                const uploadIndices: ("foreground" | "background")[] = [];

                if (foregroundImage instanceof File) {
                    uploadFiles.push(foregroundImage);
                    uploadIndices.push("foreground");
                }

                if (backgroundImage instanceof File) {
                    uploadFiles.push(backgroundImage);
                    uploadIndices.push("background");
                }

                const uploadResponse = await imageApi.uploadImages(uploadFiles);

                // 매핑된 업로드 결과 적용
                uploadIndices.forEach((type, index) => {
                    if (type === "foreground") {
                        foregroundImageUrl = uploadResponse.data[index];
                    } else if (type === "background") {
                        backgroundImageUrl = uploadResponse.data[index];
                    }
                });
            }

            if (isEdit && editingBannerId) {
                await bannerApi.updateBanner(editingBannerId, {
                    foregroundImageUrl,
                    backgroundImageUrl,
                    noticeId,
                });
                toast.success("배너가 성공적으로 수정되었습니다.");
            } else {
                await bannerApi.createBanner({
                    foregroundImageUrl,
                    backgroundImageUrl,
                    noticeId,
                });
                toast.success("배너가 성공적으로 추가되었습니다.");
            }

            handleCancel();
            fetchBanners(currentPage);
        } catch (error) { }
    };

    const handleEditBanner = (banner: Banner) => {
        setDialogOpen(true);
        setIsEdit(true);
        setEditingBannerId(banner.id);
        setForegroundImage(banner.foregroundImageUrl);
        setBackgroundImage(banner.backgroundImageUrl);
        setNoticeId(banner.notice.id);
    };

    const handleDeleteBanner = async (bannerId: string) => {
        const isConfirmed = window.confirm("정말로 이 배너를 삭제하시겠습니까?");
        if (!isConfirmed) return;

        try {
            await bannerApi.deleteBanner(bannerId);
            toast.success("배너가 삭제되었습니다.");
            fetchBanners(currentPage);
        } catch (error) { }
    };

    const handleCancel = () => {
        setDialogOpen(false);
        setIsEdit(false);
        setEditingBannerId(null);
        setForegroundImage(null);
        setBackgroundImage(null);
        setNoticeId("");
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        fetchBanners(page - 1);
    };

    useEffect(() => {
        fetchBanners(0);
        fetchNotices();
    }, []);

    return (
        <Box p={4}>
            <PageHeader
                title="배너 관리"
                action={
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setDialogOpen(true)}
                    >
                        배너 추가
                    </Button>
                }
            />
            <Grid container spacing={3}>
                {banners.length > 0 ? (
                    banners.map((banner) => (
                        <Grid item xs={12} sm={6} lg={4} key={banner.id}>
                            <StyledBannerBox>
                                {/* 배경 및 포어그라운드 이미지 */}
                                <Box
                                    sx={{
                                        width: "100%", // 360px에서 100%로 변경
                                        maxWidth: "360px", // 최대 너비 추가
                                        height: "80px",
                                        position: "relative",
                                        margin: "0 auto",
                                    }}
                                >
                                    {/* Background Image */}
                                    <img
                                        src={banner.backgroundImageUrl}
                                        alt="Background"
                                        draggable="false"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            userSelect: "none",
                                        }}
                                    />
                                    {/* Foreground Image */}
                                    <img
                                        src={banner.foregroundImageUrl}
                                        alt="Foreground"
                                        draggable="false"
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "85%", // 300px에서 85%로 변경
                                            maxWidth: "300px", // 최대 너비 추가
                                            height: "80px",
                                            objectFit: "contain",
                                            userSelect: "none",
                                        }}
                                    />
                                </Box>

                                {/* 배너 정보 및 버튼 */}
                                <Box p={2} sx={{ backgroundColor: '#fafafa', borderTop: '1px solid #e0e0e0' }}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, color: '#424242' }}>
                                        {banner.notice.title || "공지사항 없음"}
                                    </Typography>
                                    <ActionButtonsBox>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<EditIcon />}
                                            onClick={() => handleEditBanner(banner)}
                                            sx={{ flex: 1, mr: 1 }}
                                        >
                                            수정
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => handleDeleteBanner(banner.id)}
                                            sx={{ flex: 1, ml: 1 }}
                                        >
                                            삭제
                                        </Button>
                                    </ActionButtonsBox>
                                </Box>
                            </StyledBannerBox>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary" align="center">
                            등록된 배너가 없습니다.
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={currentPage + 1}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            <Dialog open={dialogOpen} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogTitle>
                    {isEdit ? (
                        <>
                            <EditIcon sx={{ mr: 1 }} />
                            배너 수정
                        </>
                    ) : (
                        <>
                            <AddIcon sx={{ mr: 1 }} />
                            배너 추가
                        </>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Typography variant="subtitle1">미리보기</Typography>
                        <Box
                            sx={{
                                width: "100%", // 360px에서 100%로 변경
                                maxWidth: "360px", // 최대 너비 추가
                                height: "80px",
                                position: "relative",
                                margin: "0 auto",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                overflow: "hidden",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            {backgroundImage && (
                                <img
                                    src={
                                        typeof backgroundImage === "string"
                                            ? backgroundImage
                                            : URL.createObjectURL(backgroundImage)
                                    }
                                    alt="Background Preview"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            )}
                            {foregroundImage && (
                                <img
                                    src={
                                        typeof foregroundImage === "string"
                                            ? foregroundImage
                                            : URL.createObjectURL(foregroundImage)
                                    }
                                    alt="Foreground Preview"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "85%", // 300px에서 85%로 변경
                                        maxWidth: "300px", // 최대 너비 추가
                                        height: "80px",
                                        objectFit: "contain",
                                    }}
                                />
                            )}
                        </Box>

                        <Typography variant="subtitle1">포어그라운드(300*80) 이미지 업로드</Typography>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setForegroundImage(e.target.files?.[0] || null)}
                            style={{ marginBottom: "16px" }}
                        />

                        <Typography variant="subtitle1">백그라운드(360*80) 이미지 업로드</Typography>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setBackgroundImage(e.target.files?.[0] || null)}
                            style={{ marginBottom: "16px" }}
                        />

                        <Typography variant="subtitle1">공지사항 선택</Typography>
                        <Select
                            value={noticeId}
                            onChange={(e) => setNoticeId(e.target.value)}
                            fullWidth
                        >
                            {notices.map((notice) => (
                                <MenuItem key={notice.id} value={notice.id}>
                                    {notice.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: "16px" }}>
                    <Button
                        onClick={handleCancel}
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        color="error"
                    >
                        취소
                    </Button>
                    <Button
                        onClick={handleSaveBanner}
                        variant="contained"
                        color="primary"
                        disabled={!foregroundImage || !backgroundImage || !noticeId}
                        startIcon={isEdit ? <EditIcon /> : <AddIcon />}
                    >
                        {isEdit ? "수정" : "추가"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Banners;
