import React from 'react';
import { Chip, ChipProps } from '@mui/material';

interface FilterChipProps extends Omit<ChipProps, 'sx'> {
    isSelected?: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({ isSelected, ...props }) => {
    return (
        <Chip
            {...props}
            color={isSelected ? "primary" : "default"}
            sx={{
                padding: "20px 24px",
                fontSize: "15px",
                fontWeight: 600,
                borderRadius: "20px",
                transition: "all 0.2s ease",
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
            }}
        />
    );
};

export default FilterChip;
