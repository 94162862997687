import XLSX from 'xlsx-js-style';
import dayjs from "dayjs";
import { DailyDataAnalysisResponse } from "../../types/analytics/response/DailyDataAnalysisResponse";
import { formatDate } from '../../utils/date-format';

export const generateDailyDataExcel = async (
    allData: DailyDataAnalysisResponse[],
) => {
    const currentTime = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    const fileName = `부라보_데이터_${currentTime}.xlsx`;
    const workbook = XLSX.utils.book_new();

    const hourlyHeaders = Array.from({ length: 24 }, (_, i) => `${i}시 ~ ${i + 1}시`);
    const header = [
        "날짜",
        "일 가입자 수",
        "일 방문자 수",
        "일 업로드 게시글 수",
        "일 게시글 작성자 수",
        "일 게시글 조회 수",
        "일 평균 게시글 조회 수",
        "일 게시글 조회 사용자 수",
        "일 업로드 댓글 수",
        "일 댓글 작성자 수",
        "일 게시글 좋아요 수",
        "일 게시글 좋아요 누른 사용자 수",
        "일 댓글 좋아요 수",
        "일 댓글 좋아요 누른 사용자 수",
        "누적 가입자 수",
        "누적 탈퇴 수",
        "누적 비활성화 수",
        "누적 게시글 수",
        "누적 댓글 수",
        "누적 게시글 좋아요 수",
        "누적 댓글 좋아요 수",
        "시간별 활성 사용자 수 ➡️",
        ...hourlyHeaders
    ];

    const data = allData.map(item => [
        formatDate(dayjs(item.date)),
        item.dailyNewUsers,
        item.dailyActiveUsers,
        item.dailyUploadedFeeds,
        item.dailyFeedWritingUsers,
        item.dailyFeedViews,
        item.dailyAvgFeedViewsPerUser.toFixed(2),
        item.dailyFeedViewingUsers,
        item.dailyUploadedComments,
        item.dailyCommentWritingUsers,
        item.dailyFeedLikes,
        item.dailyFeedLikingUsers,
        item.dailyCommentLikes,
        item.dailyCommentLikingUsers,
        item.cumulativeTotalUsers,
        item.cumulativeWithdrawnUsers,
        item.cumulativeInactiveUsers,
        item.cumulativeTotalFeeds,
        item.cumulativeTotalComments,
        item.cumulativeFeedLikes,
        item.cumulativeCommentLikes,
        "",  // 시간별 데이터 구분자
        ...item.hourlyActiveUsers
    ]);

    const allExcelData = [header, ...data];
    const worksheet = XLSX.utils.aoa_to_sheet(allExcelData);

    // 스타일 적용
    applyHeaderStyles(worksheet, header);
    applyDataStyles(worksheet, data);
    applyColumnWidths(worksheet);

    XLSX.utils.book_append_sheet(workbook, worksheet, "일별 데이터");
    XLSX.writeFile(workbook, fileName);
};

const applyHeaderStyles = (worksheet: XLSX.WorkSheet, header: string[]) => {
    header.forEach((_, index) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
        worksheet[cellAddress].s = {
            fill: { fgColor: { rgb: "FFDDC1" } },
            font: { bold: true, color: { rgb: "000000" } },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } }
            }
        };
    });
};

const applyDataStyles = (worksheet: XLSX.WorkSheet, data: any[][]) => {
    data.forEach((row, rowIndex) => {
        row.forEach((cellValue, cellIndex) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 1, c: cellIndex });
            if (!worksheet[cellAddress]) {
                worksheet[cellAddress] = { v: cellValue || '' };
            }
            worksheet[cellAddress].s = {
                border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                    bottom: { style: "thin", color: { rgb: "000000" } },
                    left: { style: "thin", color: { rgb: "000000" } },
                    right: { style: "thin", color: { rgb: "000000" } }
                }
            };
        });
    });
};

const applyColumnWidths = (worksheet: XLSX.WorkSheet) => {
    const hourlyCols = Array.from({ length: 24 }, () => ({ wch: 10 }));
    worksheet['!cols'] = [
        { wch: 12 }, // 날짜
        { wch: 15 }, // 일 가입자 수
        { wch: 15 }, // 일 방문자 수
        { wch: 20 }, // 일 업로드 게시글 수
        { wch: 20 }, // 일 게시글 작성자 수
        { wch: 18 }, // 일 게시글 조회 수
        { wch: 18 }, // 일 평균 게시글 조회 수
        { wch: 20 }, // 일 게시글 조회 사용자 수
        { wch: 18 }, // 일 업로드 댓글 수
        { wch: 20 }, // 일 댓글 작성자 수
        { wch: 18 }, // 일 게시글 좋아요 수
        { wch: 22 }, // 일 게시글 좋아요 누른 사용자 수
        { wch: 18 }, // 일 댓글 좋아요 수
        { wch: 22 }, // 일 댓글 좋아요 누른 사용자 수
        { wch: 20 }, // 누적 가입자 수
        { wch: 15 }, // 누적 탈퇴 수
        { wch: 18 }, // 누적 비활성화 수
        { wch: 20 }, // 누적 게시글 수
        { wch: 18 }, // 누적 댓글 수
        { wch: 20 }, // 누적 게시글 좋아요 수
        { wch: 20 }, // 누적 댓글 좋아요 수
        { wch: 25 }, // 시간별 활성 사용자 수 ➡️
        ...hourlyCols, // 24시간별 활성 사용자 수
    ];
};
