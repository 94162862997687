import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader";
import { AlertBox, AlertText } from "../../components/AlertBox";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import { userApi } from "../../api/user-api";

const Whitelist: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validatePhoneNumber = (number: string) => {
        const regex = /^010-\d{4}-\d{4}$/;
        return regex.test(number);
    };

    const formatToDisplayNumber = (numbers: string) => {
        let formatted = numbers;
        if (numbers.length >= 3) {
            formatted = numbers.slice(0, 3) + '-' + numbers.slice(3);
        }
        if (numbers.length >= 7) {
            formatted = formatted.slice(0, 8) + '-' + formatted.slice(8);
        }
        return formatted;
    };

    const formatToServerNumber = (number: string) => {
        const cleaned = number.replace(/-/g, '');
        return `+82 ${cleaned.slice(1, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7)}`;
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        let prevValue = phoneNumber;

        // 백스페이스로 지우는 경우
        if (value.length < prevValue.length) {
            // 하이픈이 마지막 문자인 경우 하이픈까지 지움
            if (prevValue.endsWith('-')) {
                value = value.slice(0, -1);
            }
        }

        // 숫자만 추출
        const numbers = value.replace(/[^\d]/g, '');

        if (numbers.length <= 11) {
            setPhoneNumber(formatToDisplayNumber(numbers));
        }
    };

    const handleSubmit = async () => {
        if (!validatePhoneNumber(phoneNumber)) {
            toast.error("올바른 휴대폰 번호 형식을 입력해주세요.");
            return;
        }

        const serverFormatNumber = formatToServerNumber(phoneNumber);
        const isConfirmed = window.confirm(`${serverFormatNumber} 번호를 화이트리스트에 추가하시겠습니까?`);
        if (!isConfirmed) return;

        try {
            setIsLoading(true);
            await userApi.registerSignUpWhitelist({
                phoneNumber: serverFormatNumber
            });
            setPhoneNumber("");
            toast.success("화이트리스트에 추가되었습니다.");
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box p={4}>
            <PageHeader title="화이트리스트 추가" />

            <AlertBox
                bgcolor="#e3f2fd"
                borderColor="#2196f3"
                padding="16px"
                sx={{ mb: 3 }}
            >
                <Box display="flex" alignItems="center" gap={1}>
                    <InfoIcon color="info" />
                    <AlertText textColor="#1976d2">
                        카카오톡에 등록된 전화번호와 동일한 번호로 등록해야 화이트리스트 적용됩니다. 중복되면 에러가 발생합니다.
                    </AlertText>
                </Box>
            </AlertBox>

            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                maxWidth="400px"
                sx={{
                    backgroundColor: "#fff",
                    padding: "24px",
                    borderRadius: "12px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
                }}
            >
                <TextField
                    label="휴대폰 번호"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    variant="outlined"
                    placeholder="010-1234-5678"
                    helperText="휴대폰 번호를 입력해주세요"
                    error={phoneNumber !== "" && !validatePhoneNumber(phoneNumber)}
                    inputProps={{
                        maxLength: 13
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading || !validatePhoneNumber(phoneNumber)}
                    startIcon={<AddIcon />}
                    sx={{
                        height: "48px",
                        fontWeight: "bold",
                        borderRadius: "8px"
                    }}
                >
                    {isLoading ? "추가 중..." : "추가하기"}
                </Button>
            </Box>
        </Box>
    );
};

export default Whitelist;
