import React from "react";
import styled from "styled-components";
import { Typography, Divider, Box } from "@mui/material";

interface PageHeaderProps {
    title: string;
    subtitle?: string;
    action?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, action }) => {
    return (
        <HeaderContainer>
            <ContentWrapper>
                <TitleWrapper>
                    <StyledTitle variant="h4">
                        {title}
                    </StyledTitle>
                    {action && <ActionSection>{action}</ActionSection>}
                </TitleWrapper>
                {subtitle && (
                    <StyledSubtitle variant="body1" color="textSecondary">
                        {subtitle}
                    </StyledSubtitle>
                )}
            </ContentWrapper>
            <StyledDivider />
        </HeaderContainer>
    );
};

const HeaderContainer = styled(Box)`
    margin-bottom: 32px;
`;

const ContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TitleWrapper = styled(Box)`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const StyledTitle = styled(Typography)`
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 4px;
`;

const StyledSubtitle = styled(Typography)`
    font-size: 1rem;
    color: #666666;
`;

const ActionSection = styled(Box)`
    display: flex;
    align-items: center;
`;

const StyledDivider = styled(Divider)`
    margin-top: 24px;
    background-color: #e0e0e0;
`;

export default PageHeader;