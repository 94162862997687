import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    Box,
    Typography,
    Button,
    Dialog,
    IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { noticeApi } from "../../api/notice-api";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import ImageViewerDialog from "./ImageViewerDialog";
import NoticeDialog from "./NoticeDialog";
import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoticeDetailDialog from "./NoticeDetailDialog";

const Notice: React.FC = () => {
    const [notices, setNotices] = useState<NoticeResponse[]>([]);
    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState<NoticeResponse | null>(null);

    const fetchNotices = async () => {
        try {
            const response = await noticeApi.getNotices();
            setNotices(response.data);
        } catch (error) {
        }
    };

    const handleDeleteNotice = async (noticeId: string) => {
        const isConfirmed = window.confirm("정말로 공지사항을 삭제하시겠습니까?");
        if (!isConfirmed) return;

        try {
            await noticeApi.deleteNotice(noticeId);
            toast.success("공지사항이 삭제되었습니다.");
            fetchNotices();
        } catch (error) {
        }
    };

    const handleEditNotice = (notice: NoticeResponse) => {
        setSelectedNotice(notice);
        setEditDialogOpen(true);
    };

    const handleViewNotice = (notice: NoticeResponse) => {
        setSelectedNotice(notice);
        setViewDialogOpen(true);
    };

    const handleViewImage = (image: string) => {
        setSelectedImage(image);
        setImageViewerOpen(true);
    };

    useEffect(() => {
        fetchNotices();
    }, []);

    return (
        <Box p={4}>
            <PageHeader title="공지사항 관리" action={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSelectedNotice(null); // 작성 모드
                        setEditDialogOpen(true);
                    }}
                    startIcon={<AddIcon />}
                >
                    공지사항 작성
                </Button>
            } />

            <Box>
                {notices.map((notice) => (
                    <NoticeCard key={notice.id}>
                        <Box onClick={() => handleViewNotice(notice)} sx={{ cursor: 'pointer' }}>
                            <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>{notice.title}</Typography>
                            <Typography variant="body2" sx={{ color: '#757575', mt: 1 }}>
                                {notice.createdAt}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 2, color: '#424242', overflow: "hidden", textOverflow: "ellipsis", maxHeight: "4.5em", lineHeight: 1.6 }}>
                                {notice.content}
                            </Typography>
                        </Box>
                        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                            {notice.images.map((image, index) => (
                                <NoticeImage
                                    key={index}
                                    src={image}
                                    alt={`공지사항 이미지 ${index + 1}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleViewImage(image);
                                    }}
                                />
                            ))}
                        </Box>
                        <Box display="flex" gap={1} marginTop={"16px"}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditNotice(notice);
                                }}
                                startIcon={<EditIcon />}
                            >
                                수정
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteNotice(notice.id);
                                }}
                                startIcon={<DeleteIcon />}
                            >
                                삭제
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewNotice(notice);
                                }}
                                startIcon={<VisibilityIcon />}
                            >
                                상세 보기
                            </Button>
                        </Box>
                    </NoticeCard>
                ))}
            </Box>

            {viewDialogOpen && selectedNotice && (
                <NoticeDetailDialog
                    open={viewDialogOpen}
                    notice={selectedNotice}
                    onClose={() => setViewDialogOpen(false)}
                    onImageClick={handleViewImage}
                />
            )}

            {selectedImage && (
                <ImageViewerDialog
                    open={imageViewerOpen}
                    image={selectedImage}
                    onClose={() => setImageViewerOpen(false)}
                />
            )}

            <NoticeDialog
                open={editDialogOpen}
                notice={selectedNotice || null}
                onClose={() => {
                    setEditDialogOpen(false);
                    setSelectedNotice(null); // 선택 초기화
                }}
                onActionComplete={fetchNotices} // 수정 후 목록 갱신
            />
        </Box>
    );
};

export default Notice;

const NoticeCard = styled(Box)`
  padding: 24px;
  border: none;
  border-radius: 12px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
  }
`;

const NoticeImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;
