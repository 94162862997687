import {AxiosInstance} from "axios";
import {baseApi} from "./base-api";
import {CreateBannerRequest} from "../types/banner/request/CreateBannerRequest";
import {Banner} from "../types/banner/Banner";
import {BasePageDto} from "./base-response";
import {UpdateBannerRequest} from "../types/banner/request/UpdateBannerRequest";

class BannerApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    // 🔥 배너 리스트 가져오기 (페이징 포함)
    async getBanners(params: { status?: string; page?: number; size?: number }): Promise<BasePageDto<Banner>> {
        const response = await this._api.get("/api/top-banners", { params });
        return response.data;
    }

    // 🔥 배너 생성
    async createBanner(request: CreateBannerRequest): Promise<void> {
        await this._api.post("/api/top-banners", request);
    }

    // 🔥 배너 삭제
    async deleteBanner(id: string): Promise<void> {
        await this._api.delete(`/api/top-banners/${id}`);
    }

    async updateBanner(id: string, request: UpdateBannerRequest): Promise<void> {
        await this._api.put(`/api/top-banners/${id}`, request);
    }
}

export const bannerApi = new BannerApi(baseApi);
