export enum CustomDayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY", 
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}

export const getCustomDayOfWeekLabel = (day: CustomDayOfWeek): string => {
    const labels: Record<CustomDayOfWeek, string> = {
        [CustomDayOfWeek.MONDAY]: "월요일",
        [CustomDayOfWeek.TUESDAY]: "화요일",
        [CustomDayOfWeek.WEDNESDAY]: "수요일", 
        [CustomDayOfWeek.THURSDAY]: "목요일",
        [CustomDayOfWeek.FRIDAY]: "금요일",
        [CustomDayOfWeek.SATURDAY]: "토요일",
        [CustomDayOfWeek.SUNDAY]: "일요일"
    };
    return labels[day];
};

export const getCustomDayOfWeekLabels = (days: CustomDayOfWeek[]): string => {
    if (days.length === 7) {
        return "매일";
    }
    return days.map(day => getCustomDayOfWeekLabel(day)).join(", ");
};
