export enum NotificationBatchCondition {
    NOT_EXERCISED = "NOT_EXERCISED",
    NO_POST = "NO_POST", 
    NEWLY_REGISTERED = "NEWLY_REGISTERED"
}

export const getNotificationBatchConditionLabel = (condition: NotificationBatchCondition): string => {
    const labels: Record<NotificationBatchCondition, string> = {
        [NotificationBatchCondition.NOT_EXERCISED]: "오늘 운동 인증글을 올리지 않은 사람들에게",
        [NotificationBatchCondition.NO_POST]: "게시글을 하나도 안올린 사람들에게",
        [NotificationBatchCondition.NEWLY_REGISTERED]: "오늘 가입한 사람들에게"
    };
    return labels[condition];
};

export const getNotificationBatchConditionLabels = (conditions: NotificationBatchCondition[]): string => {
    if (!conditions || conditions.length === 0) {
        return "전체";
    }
    return conditions.map(condition => getNotificationBatchConditionLabel(condition)).join(", ");
};
