export enum Pathname {
    Dashboard = "/dashboard",
    User = "/users",
    Feed = "/feeds",
    Banners = "/banners",
    BannerSet = "/banner-set",
    Report = "/report",
    PointProduct = "/point-product",
    PointExchange = "/point-exchange",
    Notice = "/notice",
    DailyDataAnalysis = "/daily-data-analysis",
    Version = "/version",
    AdminRegister = "/admin-id-register",
    Whitelist = "/whitelist",
    NotificationBatch = "/notification-batch",
}
