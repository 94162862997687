import axios from "axios";
import {getBaseErrorResponse, getBaseResponse} from "./base-response";

const getBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return "http://localhost:8080";
        case 'prod':
        default:
            return "https://api.booravo-life.com";
    }
}

export const baseApi = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        "Content-Type": "application/json",
    },
});

baseApi.interceptors.response.use(getBaseResponse, getBaseErrorResponse);
