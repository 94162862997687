import React, { useState } from "react";
import styled from "styled-components";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { userApi } from "../../api/user-api";
import PageHeader from "../../components/PageHeader";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';

const AdminRegister: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const handleRegister = async () => {
        if (!username || !password || !name) {
            toast.error("모든 필드를 입력해주세요.");
            return;
        }

        try {
            await userApi.registerAdmin({ username, password, name });
            toast.success("관리자가 성공적으로 등록되었습니다.");
            resetForm();
        } catch (error) {
        }
    };

    const resetForm = () => {
        setUsername("");
        setPassword("");
        setName("");
    };

    return (
        <OuterContainer>
            <Container>
                <PageHeader title="관리자 계정 등록" />
                <FormContainer>
                    <TextField
                        label="아이디"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="비밀번호"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="관리자 이름"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                    <Typography
                        color="error"
                        sx={{
                            mt: 2,
                            p: 2,
                            bgcolor: '#fff3f3',
                            borderRadius: 1,
                            border: '1px solid #ffcdd2'
                        }}
                    >
                        ⚠️ 관리자 계정 등록 후 승인이 필요합니다. 개발자에게 문의해 주세요.
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="outlined" color="secondary" onClick={resetForm} startIcon={<RefreshIcon />}>
                            초기화
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleRegister} startIcon={<AddIcon />}>
                            등록
                        </Button>
                    </Box>
                </FormContainer>
            </Container>
        </OuterContainer>
    );
};

export default AdminRegister;

// 스타일 정의
const OuterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const Container = styled(Box)`
  padding: 32px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;
