import styled from "styled-components";
import {Box} from "@mui/material";

export const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  border: 1px solid #ebebeb;
`;

export const UserInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
