import React, { useEffect, useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { systemApi } from "../../api/system-api";
import PageHeader from "../../components/PageHeader";
import { AlertBox, AlertText } from "../../components/AlertBox";
import InfoIcon from "@mui/icons-material/Info";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const Version: React.FC = () => {
    const [forceUpdateVersion, setForceUpdateVersion] = useState<string>("");
    const [currentVersion, setCurrentVersion] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchVersion = async () => {
        try {
            setIsLoading(true);
            const response = await systemApi.getSystemVersion();
            setForceUpdateVersion(response.forceUpdateVersionName);
            setCurrentVersion(response.currentVersionName);
        } catch (error) {
            toast.error("시스템 버전 정보를 불러오는 데 실패했습니다.");
        } finally {
            setIsLoading(false);
        }
    };

    const updateVersion = async () => {
        const isConfirmed = window.confirm("정말로 시스템 버전 정보를 업데이트하시겠습니까?");
        if (!isConfirmed) return;

        try {
            await systemApi.updateSystemVersion({
                forceUpdateVersion,
                currentVersion,
            });
            toast.success("시스템 버전 정보가 업데이트되었습니다.");
            fetchVersion();
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchVersion();
    }, []);

    return (
        <Box p={4}>
            <PageHeader title="시스템 버전 관리" />

            <AlertBox
                bgcolor="#e3f2fd"
                borderColor="#2196f3"
                padding="16px"
                sx={{ mb: 3 }}
            >
                <Box display="flex" alignItems="center" gap={1}>
                    <InfoIcon color="info" />
                    <AlertText textColor="#1976d2" whiteSpace="pre-line">
                        {
                        `1. 버전 정보를 신중하게 관리해주세요. 잘못된 설정은 앱 전체에 영향을 미칩니다.
                        2. 강제 업데이트 버전이 우선 적용됩니다.
                        3. 사용자 버전이 설정된 버전보다 낮을 경우 앱 실행 시 업데이트 팝업이 표시됩니다.`
                        }
                    </AlertText>
                </Box>
            </AlertBox>

            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                maxWidth="400px"
                sx={{
                    backgroundColor: "#fff",
                    padding: "24px",
                    borderRadius: "12px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
                }}
            >
                <TextField
                    label="강제 업데이트 버전"
                    value={forceUpdateVersion}
                    onChange={(e) => setForceUpdateVersion(e.target.value)}
                    fullWidth
                    variant="outlined"
                    helperText="사용자가 반드시 업데이트해야 하는 최소 버전"
                />
                <TextField
                    label="권장 업데이트 버전"
                    value={currentVersion}
                    onChange={(e) => setCurrentVersion(e.target.value)}
                    fullWidth
                    variant="outlined"
                    helperText="업데이트를 권장하는 최신 버전"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={updateVersion}
                    disabled={isLoading}
                    startIcon={<RocketLaunchIcon />}
                    sx={{
                        height: "48px",
                        fontWeight: "bold",
                        borderRadius: "8px"
                    }}
                >
                    {isLoading ? "업데이트 중..." : "버전 정보 업데이트"}
                </Button>
            </Box>
        </Box>
    );
};

export default Version;
