export enum NotificationBatchStatus {
    ACTIVE = "ACTIVE",
    PAUSE = "PAUSE",
    DELETED = "DELETED"
}

export const getNotificationBatchStatusLabel = (status: NotificationBatchStatus): string => {
    const labels: Record<NotificationBatchStatus, string> = {
        [NotificationBatchStatus.ACTIVE]: "활성화",
        [NotificationBatchStatus.PAUSE]: "중단",
        [NotificationBatchStatus.DELETED]: "삭제"
    };
    return labels[status];
};
