import { AxiosInstance } from "axios";
import { baseApi } from "./base-api";

class SystemApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getSystemVersion(): Promise<{ forceUpdateVersionName: string; currentVersionName: string }> {
        const response = await this._api.get("/api/system/version");
        return response.data;
    }

    async updateSystemVersion(payload: { forceUpdateVersion: string; currentVersion: string }): Promise<void> {
        await this._api.patch("/api/system/version", payload);
    }
}

export const systemApi = new SystemApi(baseApi);
