import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Chip,
    Stack,
    FormHelperText,
} from "@mui/material";
import { NotificationBatchResponse } from "../../types/notification/response/NotificationBatchResponse";
import { NotificationBatchRequest } from "../../types/notification/request/NotificationBatchRequest";
import { CustomDayOfWeek, getCustomDayOfWeekLabel } from "../../types/notification/entity/CustomDayOfWeek";
import { getNotificationBatchConditionLabel, NotificationBatchCondition } from "../../types/notification/entity/NotificationBatchCondition";
import { getNotificationLinkTypeLabel, NotificationLinkType } from "../../types/notification/entity/NotificationLinkType";
import { toast } from "react-toastify";
import { notificationBatchApi } from "../../api/notification-batch-api";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import { noticeApi } from "../../api/notice-api";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

interface Props {
    open: boolean;
    batch: NotificationBatchResponse | null;
    onClose: () => void;
    onComplete?: () => void;
}

const NotificationBatchDialog: React.FC<Props> = ({ open, batch, onClose, onComplete }) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [daysOfWeek, setDaysOfWeek] = useState<CustomDayOfWeek[]>([]);
    const [time, setTime] = useState("00:00");
    const [conditions, setConditions] = useState<NotificationBatchCondition[]>([]);
    const [linkType, setLinkType] = useState<NotificationLinkType>(NotificationLinkType.GENERAL);
    const [linkId, setLinkId] = useState<string | undefined>();
    const [notices, setNotices] = useState<NoticeResponse[]>([]);

    useEffect(() => {
        if (batch) {
            setTitle(batch.title || "");
            setContent(batch.content || "");
            setDaysOfWeek(batch.daysOfWeek || []);
            setTime(batch.time || "00:00");
            setConditions(batch.conditions || []);
            setLinkType(batch.linkType || NotificationLinkType.GENERAL);
            setLinkId(batch.linkId);
        } else {
            setTitle("");
            setContent("");
            setDaysOfWeek([]);
            setTime("00:00");
            setConditions([]);
            setLinkType(NotificationLinkType.GENERAL);
            setLinkId(undefined);
        }
    }, [batch]);

    const handleSave = async () => {
        if (!title?.trim()) {
            toast.error("제목을 입력해주세요.");
            return;
        }

        if (!content?.trim()) {
            toast.error("내용을 입력해주세요.");
            return;
        }

        if (!daysOfWeek.length) {
            toast.error("요일을 하나 이상 선택해주세요.");
            return;
        }
        if (linkType === NotificationLinkType.NOTICE && !linkId) {
            toast.error("공지사항을 선택해주세요.");
            return;
        }

        const request: NotificationBatchRequest = {
            title,
            content,
            daysOfWeek,
            time,
            conditions,
            linkType,
            linkId: linkType === NotificationLinkType.GENERAL ? undefined : linkId,
        };

        try {
            if (batch) {
                await notificationBatchApi.updateNotificationBatch(batch.id, request);
                toast.success("알림이 수정되었습니다.");
            } else {
                await notificationBatchApi.createNotificationBatch(request);
                toast.success("알림이 생성되었습니다.");
            }
            onComplete?.();
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNotices = async () => {
        try {
            const response = await noticeApi.getNotices();
            setNotices(response.data);
        } catch (error) { }
    };
    useEffect(() => {
        if (linkType === NotificationLinkType.NOTICE && notices.length === 0) {
            fetchNotices();
        }

        if (linkType === NotificationLinkType.GENERAL) {
            setLinkId(undefined);
        }
    }, [linkType, notices.length]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ 
                pt: 3, 
                pb: 1, 
                borderBottom: '1px solid #eee',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: '#1a237e',
                fontWeight: 600
            }}>
                {batch ? (
                    <>
                        <EditIcon sx={{ fontSize: 20 }} />
                        정기 알림 수정
                    </>
                ) : (
                    <>
                        <AddIcon sx={{ fontSize: 20 }} />
                        정기 알림 생성
                    </>
                )}
            </DialogTitle>
            <DialogContent sx={{ p: 4 }}>
                <Stack spacing={3.5} sx={{ mt: 2 }}>
                    <TextField
                        label="제목"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px'
                            }
                        }}
                    />

                    <TextField
                        label="내용"
                        fullWidth
                        multiline
                        rows={2}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px'
                            }
                        }}
                    />

                    <FormControl fullWidth size="small" sx={{ '& .MuiInputLabel-root': { bgcolor: 'white', px: 1 } }}>
                        <InputLabel>요일 선택</InputLabel>
                        <Select
                            multiple
                            value={daysOfWeek}
                            onChange={(e) => setDaysOfWeek(e.target.value as CustomDayOfWeek[])}
                            sx={{
                                borderRadius: '8px',
                                '& .MuiChip-root': {
                                    borderRadius: '6px',
                                    m: 0.2
                                }
                            }}
                            renderValue={(selected) => (
                                <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                    {selected.map((day) => (
                                        <Chip
                                            key={day}
                                            label={getCustomDayOfWeekLabel(day)}
                                            size="small"
                                            variant="filled"
                                            sx={{
                                                bgcolor: '#E3F2FD',
                                                color: '#1565C0',
                                                fontWeight: 500
                                            }}
                                        />
                                    ))}
                                </Stack>
                            )}
                        >
                            {Object.keys(CustomDayOfWeek).map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    sx={{
                                        borderRadius: '6px',
                                        mx: 0.5,
                                        my: 0.5,
                                        '&:hover': {
                                            bgcolor: '#E3F2FD',
                                        },
                                        '&.Mui-selected': {
                                            bgcolor: '#E3F2FD',
                                            '&:hover': {
                                                bgcolor: '#BBDEFB'
                                            }
                                        }
                                    }}
                                >
                                    {getCustomDayOfWeekLabel(key as CustomDayOfWeek)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="시간 (30분 단위로 선택)"
                        fullWidth
                        type="time"
                        value={time}
                        onChange={(e) => {
                            const minutes = parseInt(e.target.value.split(':')[1]);
                            const hours = e.target.value.split(':')[0];
                            const roundedMinutes = Math.round(minutes / 30) * 30;
                            const formattedMinutes = roundedMinutes === 60 ? '00' : String(roundedMinutes).padStart(2, '0');
                            const finalTime = `${hours}:${formattedMinutes}`;
                            setTime(finalTime);
                        }}
                        size="small"
                        inputProps={{
                            step: 1800
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px'
                            }
                        }}
                    />

                    <FormControl fullWidth size="small" sx={{ '& .MuiInputLabel-root': { bgcolor: 'white', px: 1 } }}>
                        <InputLabel>조건 선택</InputLabel>
                        <Select
                            multiple
                            value={conditions}
                            onChange={(e) => setConditions(e.target.value as NotificationBatchCondition[])}
                            sx={{
                                borderRadius: '8px',
                                '& .MuiChip-root': {
                                    borderRadius: '6px',
                                    m: 0.2
                                }
                            }}
                            renderValue={(selected) => (
                                <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                    {selected.map((condition) => (
                                        <Chip
                                            key={condition}
                                            label={getNotificationBatchConditionLabel(condition)}
                                            size="small"
                                            variant="filled"
                                            sx={{
                                                bgcolor: '#F3E5F5',
                                                color: '#6A1B9A',
                                                fontWeight: 500
                                            }}
                                        />
                                    ))}
                                </Stack>
                            )}
                        >
                            {Object.keys(NotificationBatchCondition).map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    sx={{
                                        borderRadius: '6px',
                                        mx: 0.5,
                                        my: 0.5,
                                        '&:hover': {
                                            bgcolor: '#F3E5F5',
                                        },
                                        '&.Mui-selected': {
                                            bgcolor: '#F3E5F5',
                                            '&:hover': {
                                                bgcolor: '#E1BEE7'
                                            }
                                        }
                                    }}
                                >
                                    {getNotificationBatchConditionLabel(key as NotificationBatchCondition)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{ color: 'text.secondary', mx: 1.5 }}>
                            💡 조건을 선택하지 않으면 전체 회원에게 발송됩니다
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ '& .MuiInputLabel-root': { bgcolor: 'white', px: 1 } }}>
                        <InputLabel>연결 유형</InputLabel>
                        <Select
                            value={linkType}
                            onChange={(e) => setLinkType(e.target.value as NotificationLinkType)}
                            sx={{
                                borderRadius: '8px'
                            }}
                        >
                            {Object.keys(NotificationLinkType).map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    sx={{
                                        borderRadius: '6px',
                                        mx: 0.5,
                                        my: 0.5,
                                        '&:hover': {
                                            bgcolor: '#EDE7F6',
                                        },
                                        '&.Mui-selected': {
                                            bgcolor: '#EDE7F6',
                                            '&:hover': {
                                                bgcolor: '#D1C4E9'
                                            }
                                        }
                                    }}
                                >
                                    {getNotificationLinkTypeLabel(key as NotificationLinkType)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {linkType === NotificationLinkType.NOTICE ? (
                        <FormControl fullWidth size="small" sx={{ '& .MuiInputLabel-root': { bgcolor: 'white', px: 1 } }}>
                            <InputLabel>공지사항</InputLabel>
                            <Select
                                value={linkId || ""}
                                onChange={(e) => setLinkId(e.target.value)}
                                sx={{
                                    borderRadius: '8px'
                                }}
                            >
                                {notices.map((notice) => (
                                    <MenuItem
                                        key={notice.id}
                                        value={notice.id}
                                        sx={{
                                            borderRadius: '6px',
                                            mx: 0.5,
                                            my: 0.5,
                                            '&:hover': {
                                                bgcolor: '#EDE7F6',
                                            },
                                            '&.Mui-selected': {
                                                bgcolor: '#EDE7F6',
                                                '&:hover': {
                                                    bgcolor: '#D1C4E9'
                                                }
                                            }
                                        }}
                                    >
                                        {notice.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : linkType !== NotificationLinkType.GENERAL && (
                        <TextField
                            label="연결 ID"
                            fullWidth
                            value={linkId || ""}
                            onChange={(e) => setLinkId(e.target.value)}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px'
                                }
                            }}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ px: 4, pb: 3, gap: 1 }}>
                <Button
                    onClick={onClose}
                    color="inherit"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    sx={{
                        borderRadius: '8px',
                        px: 3,
                        borderColor: 'grey.300',
                        '&:hover': {
                            borderColor: 'grey.400',
                            bgcolor: 'grey.50'
                        }
                    }}
                >
                    취소
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{
                        borderRadius: '8px',
                        px: 3,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    저장
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationBatchDialog;