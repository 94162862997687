import React, { useEffect, useState } from "react";
import {
    Box,
    TableBody,
    Pagination,
    TextField,
    Button,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Tooltip,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import { analyticsApi } from "../../api/analytics-api";
import { DailyDataAnalysisResponse } from "../../types/analytics/response/DailyDataAnalysisResponse";
import {
    StyledTable,
    StyledTableCell,
    StyledTableContainer,
    StyledTableHead,
    StyledTableRow
} from "../../components/Table";
import { formatDate } from "../../utils/date-format";
import dayjs from "dayjs";
import { generateDailyDataExcel } from "../../components/excel/DailyDataExcelGenerator";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import TableChartIcon from "@mui/icons-material/TableChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StatisticsRow from "../../components/StatisticsRow";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const DailyDataAnalysis: React.FC = () => {
    const [data, setData] = useState<DailyDataAnalysisResponse[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [viewMode, setViewMode] = useState<'table' | 'chart'>('table');
    const [yAxisType, setYAxisType] = useState<'linear' | 'separate'>('linear');

    const [startDate, setStartDate] = useState<string>(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

    const fetchDailyDataAnalytics = async () => {
        setLoading(true);
        try {
            const response = await analyticsApi.getDailyDataAnalysis({
                page: page - 1,
                size: viewMode === 'chart' ? 10000 : 10,
                startDate: startDate || undefined,
                endDate: endDate || undefined,
            });
            setData(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error("데이터 불러오기에 실패했습니다.", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleFilterApply = () => {
        setPage(1);
        fetchDailyDataAnalytics();
    };

    const handleDownloadExcel = async () => {
        try {
            setLoading(true);
            let allData: DailyDataAnalysisResponse[] = [];
            let page = 1;
            let totalPages = 1;

            do {
                const response = await analyticsApi.getDailyDataAnalysis({
                    page: page - 1,
                    size: 100,
                    startDate: startDate || undefined,
                    endDate: endDate || undefined,
                });
                if (response && response.contents) {
                    allData = [...allData, ...response.contents];
                    totalPages = response.totalPages;
                } else {
                    throw new Error("데이터를 불러오는 중 오류 발생");
                }
                page++;
            } while (page <= totalPages);

            await generateDailyDataExcel(allData);
            toast.success("엑셀 파일이 다운로드되었습니다.");
        } catch (error) {
            console.error("엑셀 다운로드 중 오류 발생", error);
        } finally {
            setLoading(false);
        }
    };

    const metricsOptions = [
        { value: 'dailyNewUsers', label: '일 가입자 수' },
        { value: 'dailyActiveUsers', label: '일 방문자 수' },
        { value: 'dailyUploadedFeeds', label: '일 업로드 게시글 수' },
        { value: 'dailyFeedWritingUsers', label: '일 게시글 작성자 수' },
        { value: 'dailyFeedViews', label: '일 게시글 조회 수' },
        { value: 'dailyAvgFeedViewsPerUser', label: '일 평균 게시글 조회 수' },
        { value: 'dailyFeedViewingUsers', label: '일 게시글 조회 사용자 수' },
        { value: 'dailyUploadedComments', label: '일 업로드 댓글 수' },
        { value: 'dailyCommentWritingUsers', label: '일 댓글 작성자 수' },
        { value: 'dailyFeedLikes', label: '일 게시글 좋아요 수' },
        { value: 'dailyFeedLikingUsers', label: '일 게시글 좋아요 사용자 수' },
        { value: 'dailyCommentLikes', label: '일 댓글 좋아요 수' },
        { value: 'dailyCommentLikingUsers', label: '일 댓글 좋아요 사용자 수' },
        { value: 'cumulativeTotalUsers', label: '누적 가입자 수' },
        { value: 'cumulativeWithdrawnUsers', label: '누적 탈퇴 수' },
        { value: 'cumulativeInactiveUsers', label: '누적 비활성화 수' },
        { value: 'cumulativeTotalFeeds', label: '누적 게시글 수' },
        { value: 'cumulativeTotalComments', label: '누적 댓글 수' },
        { value: 'cumulativeFeedLikes', label: '누적 게시글 좋아요 수' },
        { value: 'cumulativeCommentLikes', label: '누적 댓글 좋아요 수' }
    ];

    const [selectedMetrics, setSelectedMetrics] = useState<string[]>([
        'dailyNewUsers',
        'dailyActiveUsers'
    ]);

    const renderChart = () => {
        return (
            <Box>
                <Box sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: 2,
                    p: 1.5,
                    mb: 1.5,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#333' }}>
                            Y축 스케일 타입
                        </Typography>
                        <ToggleButtonGroup
                            value={yAxisType}
                            exclusive
                            onChange={(e, value) => value && setYAxisType(value)}
                            size="small"
                        >
                            <ToggleButton value="linear">기본 스케일</ToggleButton>
                            <ToggleButton value="separate">개별 축</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 600, color: '#333' }}>
                        차트에 표시할 지표 선택
                    </Typography>
                    <FormGroup row sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                        gap: 0.25
                    }}>
                        {metricsOptions.map(option => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={selectedMetrics.includes(option.value)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedMetrics([...selectedMetrics, option.value]);
                                            } else {
                                                setSelectedMetrics(selectedMetrics.filter(m => m !== option.value));
                                            }
                                        }}
                                        size="small"
                                        sx={{
                                            color: '#666',
                                            '&.Mui-checked': {
                                                color: '#2196f3'
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ color: '#444' }}>
                                        {option.label}
                                    </Typography>
                                }
                                sx={{
                                    margin: 0,
                                    padding: '2px 4px',
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: 'rgba(0,0,0,0.02)'
                                    }
                                }}
                            />
                        ))}
                    </FormGroup>
                </Box>

                <ResponsiveContainer width="100%" height={600}>
                    <LineChart
                        data={[...data].reverse()}
                        margin={{ top: 20, right: 50, left: 50, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) => formatDate(dayjs(date))}
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            stroke="#666"
                            tick={{
                                fill: '#666',
                                fontSize: 12,
                                fontWeight: 500
                            }}
                        />
                        {yAxisType === 'separate' ? (
                            selectedMetrics.map((metric, index) => (
                                <YAxis
                                    key={metric}
                                    yAxisId={metric}
                                    orientation={index % 2 === 0 ? "left" : "right"}
                                    domain={[0, 'auto']}
                                    tick={{ fontSize: 12 }}
                                    tickFormatter={(value) => value.toLocaleString()}
                                    label={{
                                        value: metricsOptions.find(m => m.value === metric)?.label,
                                        angle: -90,
                                        position: index % 2 === 0 ? 'insideLeft' : 'insideRight',
                                        offset: 10,
                                        style: {
                                            textAnchor: 'middle',
                                            fill: '#666',
                                            fontSize: 12,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            ))
                        ) : (
                            <YAxis
                                domain={[0, 'auto']}
                                tickFormatter={(value) => value.toLocaleString()}
                                stroke="#666"
                                tick={{
                                    fill: '#666',
                                    fontSize: 12,
                                    fontWeight: 500
                                }}
                            />
                        )}
                        <RechartsTooltip
                            contentStyle={{
                                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                border: 'none',
                                borderRadius: '4px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                                padding: '8px'
                            }}
                            formatter={(value: any, name: string) => {
                                return [value.toLocaleString(), name];
                            }}
                            labelFormatter={(label) => formatDate(dayjs(label))}
                            cursor={{ stroke: '#666', strokeWidth: 1 }}
                        />
                        <Legend
                            layout="horizontal"
                            verticalAlign="top"
                            wrapperStyle={{
                                paddingBottom: '20px',
                                fontSize: '12px'
                            }}
                        />
                        {selectedMetrics.map((metric, index) => {
                            const hue = (index * 137.5 + 45) % 360; // 황금각을 사용하여 더 다양한 색상 분포
                            const saturation = 65 + (index % 2) * 20; // 채도 변화
                            const lightness = 45 + (index % 3) * 5; // 명도 변화
                            const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                            
                            return (
                                <Line
                                    key={metric}
                                    type="monotone"
                                    dataKey={metric}
                                    name={metricsOptions.find(m => m.value === metric)?.label}
                                    stroke={color}
                                    yAxisId={yAxisType === 'separate' ? metric : undefined}
                                    dot={false}
                                    strokeWidth={2}
                                    activeDot={{
                                        r: 6,
                                        stroke: color,
                                        strokeWidth: 2,
                                        fill: '#fff'
                                    }}
                                />
                            );
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        );
    };

    useEffect(() => {
        fetchDailyDataAnalytics();
    }, [page, viewMode]);

    return (
        <Box p={4}>
            <PageHeader title="일일 데이터 분석" subtitle="해당 데이터는 매일 0시에 SNAPSHOT으로 기록 되는 데이터입니다." />

            <Box display="flex" alignItems="center" gap={2} mb={3}>
                <TextField
                    label="시작일 (yyyy-MM-dd)"
                    variant="outlined"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="종료일 (yyyy-MM-dd)"
                    variant="outlined"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFilterApply}
                    startIcon={<SearchIcon />}
                    disabled={loading}
                >
                    조회
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleDownloadExcel}
                    startIcon={<DownloadIcon />}
                    disabled={loading}
                >
                    필터 전체 엑셀 다운로드
                </Button>
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={(e, newMode) => {
                        if (newMode) {
                            setViewMode(newMode);
                            setPage(1);
                        }
                    }}
                    aria-label="view mode"
                >
                    <ToggleButton value="table" aria-label="table view">
                        <Tooltip title="테이블 보기">
                            <TableChartIcon />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="chart" aria-label="chart view">
                        <Tooltip title="차트 보기">
                            <ShowChartIcon />
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {viewMode === 'chart' ? (
                renderChart()
            ) : (
                <>
                    <StyledTableContainer>
                        <StyledTable>
                            <StyledTableHead>
                                <StyledTableRow>
                                    <StyledTableCell nowrap={true}>날짜</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 가입자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 방문자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 업로드 게시글 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 게시글 작성자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 게시글 조회 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 평균 게시글 조회 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 게시글 조회 사용자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 업로드 댓글 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 댓글 작성자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 게시글 좋아요 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 게시글 좋아요 사용자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 댓글 좋아요 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>일 댓글 좋아요 사용자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 가입자 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 탈퇴 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 비활성화 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 게시글 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 댓글 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 게시글 좋아요 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>누적 댓글 좋아요 수</StyledTableCell>
                                    <StyledTableCell nowrap={true}>시간별 활성 사용자</StyledTableCell>
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {loading ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={22} align="center">
                                            데이터 로딩 중...
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : data.length === 0 ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={22} align="center">
                                            데이터가 없습니다
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    data.map((item) => (
                                        <React.Fragment key={item.date}>
                                            <StyledTableRow>
                                                <StyledTableCell nowrap={true}>{formatDate(dayjs(item.date))}</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyNewUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyActiveUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyUploadedFeeds}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyFeedWritingUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyFeedViews}회</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyAvgFeedViewsPerUser.toFixed(2)}회</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyFeedViewingUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyUploadedComments}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyCommentWritingUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyFeedLikes}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyFeedLikingUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyCommentLikes}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.dailyCommentLikingUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeTotalUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeWithdrawnUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeInactiveUsers}명</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeTotalFeeds}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeTotalComments}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeFeedLikes}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>{item.cumulativeCommentLikes}개</StyledTableCell>
                                                <StyledTableCell nowrap={true}>
                                                    <StatisticsRow
                                                        title="시간별 통계"
                                                        items={item.hourlyActiveUsers.map((count, index) => ({
                                                            label: `${index}시 ~ ${index + 1}시`,
                                                            value: count === 0 ? '-' : `${count}명`
                                                        }))}
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </React.Fragment>
                                    ))
                                )}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>

                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ mt: 3 }}
                    />
                </>
            )}
        </Box>
    );
};

export default DailyDataAnalysis;
