import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from "../app/reducers/hooks";
import { userApi } from "../api/user-api";
import { baseApi } from "../api/base-api";
import { setLocalStorageAccessToken, setSessionStorageAccessToken, StorageKey } from "../utils/token";
import { loginAction } from "../app/reducers/profile-slice";

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(6),
    gap: theme.spacing(3),
    margin: 'auto',
    borderRadius: '16px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '480px',
    },
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
    background: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
    padding: 24,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        zIndex: -1,
        inset: 0,
        background: 'linear-gradient(135deg, #FB493D 0%, #FB493D 46%, #FB493D 100%)',
        opacity: 0.08,
    },
}));

export default function SignIn(props: {
    disableCustomTheme?: boolean
}) {
    const dispatch = useAppDispatch();

    const [token, setToken] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(false);

    useEffect(() => {
        const accessToken =
            sessionStorage.getItem(StorageKey.AccessToken) || localStorage.getItem(StorageKey.AccessToken);

        if (accessToken !== null && accessToken !== "") {
            baseApi.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
            dispatch(loginAction());
        }
    }, [token])

    const handleSubmit = async (id: string, password: string) => {
        const signInPayload = {
            username: id,
            password: password
        };
        try {
            const response = await userApi.signIn(signInPayload);
            const token = response.data.accessToken;

            if (rememberMe) {
                setLocalStorageAccessToken(token);
            } else {
                setSessionStorageAccessToken(token);
            }

            setToken(response.data.accessToken);

            baseApi.defaults.headers.common["Authorization"] = "Bearer " + token;
        } catch (e) {
        }
    };

    const validateInputs = () => {
        const id = document.getElementById('id') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;

        if (!id.value) {
            setEmailError(true);
            setEmailErrorMessage("아이디를 입력하세요.")
            return
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value) {
            setPasswordError(true);
            setPasswordErrorMessage('비밀번호를 입력하세요.');
            return
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        handleSubmit(id.value, password.value)
    };

    return (
        <>
            <CssBaseline enableColorScheme />
            <SignInContainer direction="column" justifyContent="center">
                <Card elevation={0}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 700,
                            color: '#1a2634',
                            marginBottom: 3
                        }}
                    >
                        부라보 관리자
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 3,
                        }}
                    >
                        <FormControl>
                            <FormLabel sx={{ marginBottom: 1, fontWeight: 500 }}>아이디</FormLabel>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                id="id"
                                type="id"
                                name="id"
                                placeholder="아이디를 입력하세요"
                                autoComplete="username"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px'
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel sx={{ marginBottom: 1, fontWeight: 500 }}>비밀번호</FormLabel>
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                name="password"
                                placeholder="비밀번호를 입력하세요"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? 'error' : 'primary'}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px'
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="remember"
                                    color="primary"
                                    checked={rememberMe}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked)}
                                />
                            }
                            label="자동 로그인"
                            sx={{ marginLeft: 0 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                            sx={{
                                height: '48px',
                                borderRadius: '12px',
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                textTransform: 'none',
                                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                boxShadow: '0 3px 12px rgba(33, 150, 243, 0.3)',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
                                }
                            }}
                        >
                            로그인
                        </Button>
                    </Box>
                </Card>
            </SignInContainer>
        </>
    );
}
