import { AxiosInstance } from "axios";
import { BasePageDto, BaseResponse } from "./base-response";
import { baseApi } from "./base-api";
import { NotificationBatchResponse } from "../types/notification/response/NotificationBatchResponse";
import { NotificationBatchRequest } from "../types/notification/request/NotificationBatchRequest";
import { NotificationBatchStatus } from "../types/notification/entity/NotificationBatchStatus";

class NotificationBatchApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getNotificationBatches(): Promise<BaseResponse<NotificationBatchResponse[]>> {
        return await this._api.get("/api/admin/notification-batches");
    }

    async createNotificationBatch(request: NotificationBatchRequest): Promise<void> {
        await this._api.post("/api/admin/notification-batches", request);
    }

    async updateNotificationBatch(batchId: string, request: NotificationBatchRequest): Promise<void> {
        await this._api.put(`/api/admin/notification-batches/${batchId}`, request);
    }

    async deleteNotificationBatch(batchId: string): Promise<void> {
        await this._api.delete(`/api/admin/notification-batches/${batchId}`);
    }

    async pauseNotificationBatch(batchId: string): Promise<void> {
        await this._api.patch(`/api/admin/notification-batches/${batchId}/pause`);
    }

    async activateNotificationBatch(batchId: string): Promise<void> {
        await this._api.patch(`/api/admin/notification-batches/${batchId}/activate`); 
    }
}

export const notificationBatchApi = new NotificationBatchApi(baseApi);
