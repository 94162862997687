import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Typography } from "@mui/material";
import { Banner } from "../types/banner/Banner";
import BannerPreview from "./BannerPreview";

interface SortableBannerSetItemProps {
    id: string;
    name: string;
    banner: Banner;
}

const SortableBannerSetItem: React.FC<SortableBannerSetItemProps> = ({
    id,
    name,
    banner,
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        boxShadow: isDragging
            ? "0 4px 8px rgba(0,0,0,0.2)"
            : "0 2px 4px rgba(0,0,0,0.1)",
    };

    return (
        <Box
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                marginBottom: "8px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#fff",
                cursor: "grab",
                transition: "box-shadow 0.2s, opacity 0.2s",
            }}
        >
            {/* 드래그 핸들 */}
            <Box
                {...listeners}
                {...attributes}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "grab",
                    marginRight: "16px",
                }}
            >
                {/* 드래그 핸들 아이콘 추가 (선택 사항) */}
                <span style={{ cursor: "grab" }}>::</span>
            </Box>

            {/* 배너 프리뷰 */}
            <BannerPreview
                backgroundImageUrl={banner.backgroundImageUrl}
                foregroundImageUrl={banner.foregroundImageUrl}
            />

            {/* 배너 제목 */}
            <Typography
                sx={{ flex: 1, fontWeight: 500, marginLeft: "16px" }}
            >
                {name}
            </Typography>
        </Box>
    );
};

export default SortableBannerSetItem;