import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Button, Chip, Divider, Pagination, TableBody, TableRow, Typography, } from "@mui/material";
import { toast } from "react-toastify";
import { pointApi } from "../../api/point-api";
import { PointExchangeResponse } from "../../types/point/response/PointExchangeResponse";
import { PointExchangeStatus } from "../../types/point/enum/PointExchangeStatus";
import { StyledTable, StyledTableCell, StyledTableContainer, StyledTableHead } from "../../components/Table";
import PageHeader from "../../components/PageHeader";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PointDisplay from "../../components/PointDisplay";
import FilterChip from '../../components/FilterChip';

const PointExchange: React.FC = () => {
    const [exchanges, setExchanges] = useState<PointExchangeResponse[]>([]);
    const [status, setStatus] = useState<PointExchangeStatus | undefined>(PointExchangeStatus.REQUEST);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchExchanges = async () => {
        try {
            const response = await pointApi.getExchanges({
                page: page - 1,
                size: 10,
                status,
            });
            setExchanges(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
        }
    };

    const handleConfirmExchange = async (exchangeId: string) => {
        const isConfirmed = window.confirm("정말로 확인 처리하시겠습니까?");
        if (!isConfirmed) return;

        try {
            await pointApi.confirmExchange(exchangeId);
            toast.success("포인트 교환이 확인 처리되었습니다.");
            fetchExchanges();
        } catch (error) {
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        fetchExchanges();
    }, [page, status]);

    return (
        <Box p={4}>
            <PageHeader
                title="상품 교환 관리"
            />

            <Box mb={3} display="flex" alignItems="center" gap={2}>
                <FilterChip
                    label="미처리"
                    isSelected={status === PointExchangeStatus.REQUEST}
                    onClick={() => setStatus(PointExchangeStatus.REQUEST)}
                    icon={<ErrorOutlineIcon sx={{ fontSize: 18 }} />}
                />
                <FilterChip
                    label="처리됨"
                    isSelected={status === PointExchangeStatus.CONFIRM}
                    onClick={() => setStatus(PointExchangeStatus.CONFIRM)}
                    icon={<CheckCircleIcon sx={{ fontSize: 18 }} />}
                />
                <FilterChip
                    label="전체"
                    isSelected={!status}
                    onClick={() => setStatus(undefined)}
                    icon={<HelpOutlineIcon sx={{ fontSize: 18 }} />}
                />
            </Box>

            {/* 교환 내역 테이블 */}
            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>교환 신청일</StyledTableCell>
                            <StyledTableCell>상품</StyledTableCell>
                            <StyledTableCell>수량</StyledTableCell>
                            <StyledTableCell>이름</StyledTableCell>
                            <StyledTableCell>전화번호</StyledTableCell>
                            <StyledTableCell>소모 포인트</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>처리</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {exchanges.map((exchange) => (
                            <TableRow key={exchange.id}>
                                <StyledTableCell>
                                    {exchange.createdAt || '-'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ProductInfo>
                                        <ProductImage
                                            src={exchange.product.image}
                                            alt={exchange.product.name}
                                        />
                                        <ProductName>{exchange.product.name}</ProductName>
                                    </ProductInfo>
                                </StyledTableCell>
                                <StyledTableCell>{exchange.quantity.toLocaleString()}</StyledTableCell>

                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={exchange.user.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt={exchange.user.nickname}
                                        />
                                        <Typography>{exchange.user.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell>{exchange.phone}</StyledTableCell>
                                <StyledTableCell><PointDisplay points={exchange.consumePoint} /></StyledTableCell>
                                <StyledTableCell>
                                    <Chip
                                        icon={
                                            exchange.status === PointExchangeStatus.REQUEST
                                                ? <ErrorOutlineIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                                : exchange.status === PointExchangeStatus.CONFIRM
                                                    ? <CheckCircleIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                                    : <HelpOutlineIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                        }
                                        label={
                                            exchange.status === PointExchangeStatus.REQUEST
                                                ? "미처리"
                                                : exchange.status === PointExchangeStatus.CONFIRM
                                                    ? "처리됨"
                                                    : "알 수 없음"
                                        }
                                        style={{
                                            backgroundColor:
                                                exchange.status === PointExchangeStatus.REQUEST
                                                    ? "#d26565"
                                                    : exchange.status === PointExchangeStatus.CONFIRM
                                                        ? "#4caf50"
                                                        : "#f57c00",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            padding: "2px 8px",
                                            borderRadius: "12px",
                                            height: "24px",
                                            fontSize: "13px",
                                            boxShadow: "none"
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {exchange.status === PointExchangeStatus.REQUEST && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleConfirmExchange(exchange.id)}
                                            startIcon={<CheckIcon sx={{ fontSize: 16 }} />}
                                        >
                                            확인
                                        </Button>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            {/* 페이지네이션 */}
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ mt: 3 }}
            />
        </Box>
    );
};

export default PointExchange;

const UserInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ebebeb;
`;

const ProductInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

const ProductName = styled(Typography)`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid #ebebeb;
`;
