import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    IconButton,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { imageApi } from "../../api/image-api";
import { SaveNoticeRequest } from "../../types/notice/request/SaveNoticeRequest";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import { noticeApi } from "../../api/notice-api";
import { v4 as uuidv4 } from "uuid";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "../../components/SortableItem";

interface ImageItem {
    id: string;
    url: string;
}

interface NoticeDialogProps {
    open: boolean;
    notice?: NoticeResponse | null; // 부모에서 전달받는 공지 데이터
    onClose: () => void;
    onActionComplete: () => void; // Callback to refresh the list
}

const NoticeDialog: React.FC<NoticeDialogProps> = ({
    open,
    notice,
    onClose,
    onActionComplete,
}) => {
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [images, setImages] = useState<ImageItem[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [sendNotification, setSendNotification] = useState<boolean>(false);

    // 초기값 설정
    useEffect(() => {
        if (open) {
            setTitle(notice?.title || "");
            setContent(notice?.content || "");
            setImages(
                notice?.images.map((url) => ({
                    id: uuidv4(),
                    url,
                })) || []
            );
            setSendNotification(false);
        } else {
            // Dialog 닫힐 때 초기화
            setTitle("");
            setContent("");
            setImages([]);
            setSendNotification(false);
        }
    }, [open, notice]);

    // 이미지 업로드 처리
    const handleUploadImages = async (files: FileList | null) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            const response = await imageApi.uploadImages(files);
            const newImages = response.data.map((url: string) => ({
                id: uuidv4(),
                url,
            }));
            setImages((prev) => [...prev, ...newImages]);
            toast.success("이미지가 업로드되었습니다.");
        } catch (error) {
        } finally {
            setUploading(false);
        }
    };

    // 이미지 삭제 처리
    const handleRemoveImage = (id: string) => {
        setImages((prev) => prev.filter((image) => image.id !== id));
    };

    // 이미지 순서 변경 처리
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setImages((items) => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over?.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    // 공지 저장
    const handleSaveNotice = async () => {
        if (!title || !content) {
            toast.error("제목과 내용을 입력해주세요.");
            return;
        }

        const request: SaveNoticeRequest = {
            title,
            content,
            images: images.map((image) => image.url),
            isNotification: sendNotification,
        };

        try {
            if (notice) {
                // 수정 모드
                await noticeApi.updateNotice(notice.id, request);
                toast.success("공지사항이 수정되었습니다.");
            } else {
                // 생성 모드
                await noticeApi.saveNotice(request);
                toast.success("공지사항이 작성되었습니다.");
            }

            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    // 센서 설정
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{notice ? "공지사항 수정" : "공지사항 작성"}</DialogTitle>
            <DialogContent>
                {notice && (
                    <AlertBox
                        bgcolor="#ffebee"
                        borderColor="#f44336"
                        borderRadius="4px"
                        padding="16px"
                        sx={{
                            marginBottom: "16px",
                        }}
                    >
                        <AlertText>
                            ⚠️ <strong>주의사항: 수정할 경우 이미 발송된 알림의 내용이 수정되지는 않습니다.</strong>
                        </AlertText>
                    </AlertBox>
                )}
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        label="제목"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="내용"
                        variant="outlined"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Box>
                        <Typography variant="subtitle1">이미지 업로드</Typography>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleUploadImages(e.target.files)}
                            disabled={uploading}
                        />
                        <ImagePreviewContainer>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={images.map(image => image.id)}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    {images.map((image, index) => (
                                        <SortableItem
                                            key={image.id}
                                            id={image.id}
                                            url={image.url}
                                            onRemove={handleRemoveImage}
                                            index={index}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </ImagePreviewContainer>
                        {!notice && (
                            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendNotification}
                                            onChange={(e) => setSendNotification(e.target.checked)}
                                            sx={{
                                                color: '#1976d2',
                                                '&.Mui-checked': {
                                                    color: '#1976d2',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{ color: '#1976d2', fontWeight: 500 }}>
                                            공지사항 알림 메시지를 전송합니다.
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                            취소
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveNotice}
                            disabled={uploading}
                        >
                            {notice ? "수정 완료" : "작성 완료"}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default NoticeDialog;

// 스타일 정의
const ImagePreviewContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  min-height: 100px;
`;

const AlertBox = styled(Box)`
  background-color: #ffebee;
  border: 1px solid #f44336;
  border-radius: 4px;
  padding: 16px;
`;

const AlertText = styled(Typography)`
  color: #f44336;
`;
