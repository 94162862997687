import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

export const StyledAccordion = styled(Accordion)({
    boxShadow: 'none',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    '&:before': {
        display: 'none',
    },
    '& .MuiAccordionSummary-root': {
        minHeight: '48px',
        '&.Mui-expanded': {
            minHeight: '48px',
            borderBottom: '1px solid #e0e0e0'
        }
    },
    '& .MuiAccordionSummary-content': {
        margin: '12px 0',
        '&.Mui-expanded': {
            margin: '12px 0'
        }
    }
});

export const StyledAccordionDetails = styled(AccordionDetails)({
    padding: '16px'
}); 
