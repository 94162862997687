import React from 'react';
import { Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

interface PointDisplayProps {
    points: number;
}

const PointDisplay: React.FC<PointDisplayProps> = ({ points }) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#FB493D',
            fontWeight: 'bold'
        }}>
            <MonetizationOnIcon sx={{ fontSize: 16, color: '#FFD700' }} />
            {points.toLocaleString()}
            <span style={{ fontSize: '0.9em', opacity: 0.8 }}>P</span>
        </Box>
    );
};

export default PointDisplay;
