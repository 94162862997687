import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography, } from "@mui/material";
import { toast } from "react-toastify";
import { pointApi } from "../../api/point-api";
import { imageApi } from "../../api/image-api";
import { AddPointProductRequest } from "../../types/point/request/AddPointProductRequest";
import { PointProductResponse } from "../../types/point/response/PointProductResponse";
import { AlertBox, AlertText } from "../../components/AlertBox";
import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import PointDisplay from "../../components/PointDisplay";

const PointProduct: React.FC = () => {
    const [products, setProducts] = useState<PointProductResponse[]>([]);
    const [dialogMode, setDialogMode] = useState<"add" | "edit">("add");
    const [editingProductId, setEditingProductId] = useState<string | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState<string>("");
    const [stock, setStock] = useState<string>("");
    const [point, setPoint] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [uploading, setUploading] = useState(false);

    const fetchProducts = async () => {
        try {
            const response = await pointApi.getProducts();
            setProducts(response.data);
        } catch (error) {
            toast.error("포인트 상품 목록을 불러오는 데 실패했습니다.");
        }
    };

    const handleUpdateProduct = async (id: string) => {
        if (!name || !stock || !point || !imageUrl) {
            toast.error("모든 필드를 입력해야 합니다.");
            return;
        }

        try {
            if (Number(stock) <= 0 || Number(point) <= 0) {
                toast.error("재고량과 필요 포인트는 0보다 커야 합니다.");
                return;
            }

            const request: AddPointProductRequest = {
                name,
                stock: Number(stock),
                point: Number(point),
                image: imageUrl,
            };

            await pointApi.updateProduct(id, request);
            toast.success("포인트 상품이 수정되었습니다.");
            fetchProducts(); // 목록 갱신
            setDialogOpen(false); // 다이얼로그 닫기
            resetForm(); // 입력값 초기화
        } catch (error) {
            toast.error("포인트 상품 수정에 실패했습니다.");
        }
    };

    const handleDeleteProduct = async (id: string) => {
        if (!window.confirm("정말로 이 상품을 삭제하시겠습니까?")) {
            return;
        }

        try {
            await pointApi.deleteProduct(id);
            toast.success("포인트 상품이 삭제되었습니다.");
            fetchProducts(); // 목록 갱신
        } catch (error) {
            toast.error("포인트 상품 삭제에 실패했습니다.");
        }
    };

    const handleUploadImage = async (files: FileList | null) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            const response = await imageApi.uploadImages(files);
            setImageUrl(response.data[0]); // 첫 번째 업로드된 이미지 URL만 사용
            toast.success("이미지가 업로드되었습니다.");
        } catch (error) {
            toast.error("이미지 업로드에 실패했습니다.");
        } finally {
            setUploading(false);
        }
    };

    const handleAddProduct = async () => {
        if (!name || !stock || !point || !imageUrl) {
            toast.error("모든 필드를 입력해야 합니다.");
            return;
        }

        try {
            if (Number(stock) <= 0 || Number(point) <= 0) {
                toast.error("재고량과 필요 포인트는 0보다 커야 합니다.");
                return;
            }

            const request: AddPointProductRequest = {
                name,
                stock: Number(stock),
                point: Number(point),
                image: imageUrl,
            };

            await pointApi.addProduct(request);
            toast.success("포인트 상품이 등록되었습니다.");
            fetchProducts(); // 목록 갱신
            setDialogOpen(false); // 다이얼로그 닫기
            resetForm(); // 입력값 초기화
        } catch (error) {
            toast.error("포인트 상품 등록에 실패했습니다.");
        }
    };

    const resetForm = () => {
        setName("");
        setStock("");
        setPoint("");
        setImageUrl("");
    };

    const handleNumberInputChange = (
        setter: React.Dispatch<React.SetStateAction<string>>
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // 🔥 입력값이 빈 값이거나, 숫자로만 이루어진 경우에만 상태 업데이트
        if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
            setter(inputValue);
        }
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleBoxClick = () => {
        fileInputRef.current?.click();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            handleUploadImage(files);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const openAddDialog = () => {
        resetForm();
        setDialogMode("add");
        setDialogOpen(true);
    };

    const openEditDialog = (product: PointProductResponse) => {
        setName(product.name);
        setStock(product.stock.toString());
        setPoint(product.point.toString());
        setImageUrl(product.image);
        setEditingProductId(product.id);
        setDialogMode("edit");
        setDialogOpen(true);
    };

    const handleSaveProduct = async () => {
        if (dialogMode === 'add') {
            await handleAddProduct();
        } else {
            if (!editingProductId) return;
            await handleUpdateProduct(editingProductId);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <Box p={4}>
            <PageHeader
                title="포인트 상품 관리"
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openAddDialog}
                        startIcon={<AddIcon />}
                    >
                        상품 등록
                    </Button>
                } />
            <GridContainer>
                {products.map((product) => (
                    <ProductCard key={product.id}>
                        <ProductImage src={product.image} alt={product.name} />
                        <ProductInfo>
                            <ProductName title={product.name}>{product.name}</ProductName>
                            <Box display="flex" alignItems="center" gap={1} mt={1}>
                                <Typography variant="body2" sx={{ color: '#666' }}>재고:</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{product.stock}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="body2" sx={{ color: '#666' }}>필요 포인트:</Typography>
                                <PointDisplay points={product.point} />
                            </Box>
                            <Box display="flex" gap="8px" mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => openEditDialog(product)}
                                    startIcon={<EditIcon />}
                                    sx={{
                                        boxShadow: 'none',
                                        '&:hover': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    수정
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => handleDeleteProduct(product.id)}
                                    startIcon={<DeleteIcon />}
                                >
                                    삭제
                                </Button>
                            </Box>
                        </ProductInfo>
                    </ProductCard>
                ))}
            </GridContainer>

            {/* 상품 등록/수정 다이얼로그 */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>{dialogMode === 'add' ? '포인트 상품 등록' : '포인트 상품 수정'}</DialogTitle>
                <DialogContent>
                    {dialogMode === 'edit' && (
                        <AlertBox
                            bgcolor="#ffebee"
                            borderColor="#f44336"
                            borderRadius="4px"
                            padding="16px"
                            sx={{
                                marginBottom: "16px"
                            }}
                        >
                            <AlertText textColor="#f44336">
                                ⚠️ <strong>주의사항:</strong> 수정이 필요할 경우 <strong>재고량, 이미지, 필요 포인트</strong>만을 수정하는 것을 추천합니다.
                                <br />
                                <strong>상품명을 수정하면 이미 교환된 상품명도 같이 수정됩니다.</strong>
                            </AlertText>
                        </AlertBox>
                    )}

                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            label="상품명"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            label="재고량"
                            type="text"
                            value={stock}
                            onChange={handleNumberInputChange(setStock)}
                            fullWidth
                        />
                        <TextField
                            label="필요 포인트"
                            type="text"
                            value={point}
                            onChange={handleNumberInputChange(setPoint)}
                            fullWidth
                        />
                        <Box>
                            <Typography variant="subtitle1">이미지 업로드</Typography>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleUploadImage(e.target.files)}
                                disabled={uploading}
                            />
                            {imageUrl ? (
                                <Box mt={2}>
                                    <ImagePreview src={imageUrl} alt="Uploaded Image" />
                                </Box>
                            ) : (
                                <Box
                                    mt={2}
                                    textAlign="center"
                                    border="1px dashed #ccc"
                                    p={2}
                                    borderRadius="8px"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                    onClick={handleBoxClick}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        이미지를 드래그하거나 클릭하여 업로드하세요
                                    </Typography>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleUploadImage(e.target.files)}
                                        disabled={uploading}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setDialogOpen(false)}
                                startIcon={<CancelIcon />}
                            >
                                취소
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveProduct}
                                disabled={uploading}
                                startIcon={dialogMode === 'add' ? <AddIcon /> : <EditIcon />}
                            >
                                {dialogMode === 'add' ? '등록' : '수정'}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default PointProduct;

// 스타일 정의
const GridContainer = styled(Box)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
`;

const ProductCard = styled(Box)`
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ProductInfo = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const ProductName = styled(Typography)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 최대 2줄 */
  -webkit-box-orient: vertical;
  min-height: 48px; /* 두 줄 높이 */
`;

const ImagePreview = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;
`;
