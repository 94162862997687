import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {loginDispatch} from "../dispatch/log-in-dispatch";
import {logoutDispatch} from "../dispatch/log-out-dispatch";

export interface ProfileState {
  isLoggedIn: boolean;
  clickSignUp: boolean;
  status: "idle" | "loading" | "failed";
}


const initialState: ProfileState = {
  isLoggedIn: false,
  clickSignUp: false,
  status: "idle",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    loginAction: loginDispatch,
    logoutAction: logoutDispatch,
  },
});

export const { loginAction, logoutAction } = profileSlice.actions;

export const profileState = (state: RootState) => state.profile;

export default profileSlice.reducer;
