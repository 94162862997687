import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledAccordion } from './StyledAccordion';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Container = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
});

const StatisticsContainer = styled(Box)({
    display: "grid",
    gap: 1
});

const Label = styled(Typography)({
    color: 'text.secondary'
});

interface StatisticsItem {
    label: string;
    value: string;
}

interface StatisticsRowProps {
    title: string;
    items: StatisticsItem[];
}

const StatisticsRow: React.FC<StatisticsRowProps> = ({ title, items }) => {
    return (
        <StyledAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <StatisticsContainer>
                    {items.map((item, index) => (
                        <Container key={index}>
                            <Label>{item.label}</Label>
                            <Typography>{item.value}</Typography>
                        </Container>
                    ))}
                </StatisticsContainer>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default StatisticsRow;
