import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SortableItemProps {
    id: string;
    onRemove: (id: string) => void;
    url: string;
    index: number;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, onRemove, url, index }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleRemove = React.useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onRemove(id);
    }, [id, onRemove]);

    return (
        <Box
            ref={setNodeRef}
            style={style}
            {...attributes}
            sx={{
                position: "relative",
                width: 100,
                height: 100,
                borderRadius: 1,
                overflow: "hidden",
                border: "1px solid #ccc",
                marginRight: 1,
                marginBottom: 1,
                touchAction: 'none',  // 모바일에서 중요
            }}
        >
            <div {...listeners} style={{ width: '100%', height: '100%' }}>
                <img
                    src={url}
                    alt={`Uploaded ${index}`}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        pointerEvents: 'none'  // 이미지가 드래그 이벤트를 방해하지 않도록
                    }}
                />
            </div>
            <div
                onClick={handleRemove}
                style={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    zIndex: 100,
                    pointerEvents: 'auto',  // 삭제 버튼은 클릭 가능하도록
                }}
            >
                <CloseIcon style={{ fontSize: 18 }} />
            </div>
        </Box>
    );
};

export default React.memo(SortableItem);  // 성능 최적화 