export enum NotificationLinkType {
    GENERAL = "GENERAL",
    NOTICE = "NOTICE"
}

export const getNotificationLinkTypeLabel = (type: NotificationLinkType): string => {
    const labels: Record<NotificationLinkType, string> = {
        [NotificationLinkType.GENERAL]: "일반",
        [NotificationLinkType.NOTICE]: "공지사항"
    };
    return labels[type];
};