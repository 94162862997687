import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, DialogTitle, DialogContent, Typography, Box, Button } from "@mui/material";
import { userApi } from "../../api/user-api";
import { toast } from "react-toastify";
import {AdminUserResponse} from "../../types/user/response/AdminUserResponse";

interface UserDetailDialogProps {
    open: boolean;
    userId: string;
    onClose: () => void;
    onActionComplete: () => void;
}

const UserDetailDialog: React.FC<UserDetailDialogProps> = ({ open, userId, onClose, onActionComplete }) => {
    const [userDetail, setUserDetail] = useState<AdminUserResponse | null>(null);

    const fetchUserDetail = async () => {
        try {
            const response = await userApi.getUserDetail(userId);
            setUserDetail(response.data);
        } catch (error) {
        }
    };

    const handleDisableUser = async () => {
        const confirm = window.confirm("해당 유저를 비활성화하시겠습니까?");
        if (!confirm) return;

        try {
            await userApi.blockUser(userId);
            toast.success("유저가 비활성화되었습니다.");
            onActionComplete();
            onClose();
        } catch (error) {
            toast.error("유저 비활성화에 실패했습니다.");
        }
    };

    useEffect(() => {
        if (open) {
            fetchUserDetail();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>유저 상세 정보</DialogTitle>
            <DialogContent>
                {userDetail ? (
                    <Box>
                        <ProfileInfo>
                            <ProfileImage src={userDetail.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`} />
                            <Typography variant="h6">{userDetail.nickname}</Typography>
                        </ProfileInfo>
                        <Typography>운동 목표: {userDetail.goal}</Typography>
                        <Typography>운동 일차: {userDetail.dayOfExercise}</Typography>
                        <Typography>포인트: {userDetail.point}</Typography>
                        <Typography>유저 상태: {userDetail.status}</Typography>
                        <Button variant="contained" color="error" onClick={handleDisableUser}>
                            유저 비활성화
                        </Button>
                    </Box>
                ) : (
                    <Typography>유저 정보를 불러오는 중입니다...</Typography>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default UserDetailDialog;

const ProfileInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const ProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #ebebeb;
`;
